import window from './window';

interface NavigatorLike {
  userLanguage?: string;
  language?: string;
  languages?: readonly string[];
}

export function getSystemLocale(
  navigator: NavigatorLike = <NavigatorLike>window.navigator,
): string {
  let locale = 'en';

  if (navigator.userLanguage) {
    locale = navigator.userLanguage;
  } else if (navigator.language) {
    locale = navigator.language;
  } else if (navigator.languages && navigator.languages.length > 0) {
    [locale] = navigator.languages;
  }

  return locale.replace('-', '_');
}
