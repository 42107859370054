import { FieldError, ValidationRule } from '../../types';

type Validator = (data: any, rule: ValidationRule) => string | undefined;

export const validateField = (
  data,
  validationRule: ValidationRule,
  validators: Record<string, Validator>,
): string | undefined => {
  const { type } = validationRule;
  const validator = validators[type];

  if (!validator) {
    return 'CANNOT_VALIDATE';
  }

  return validator(data, validationRule);
};

export const getFieldError = (
  data,
  validationRule: ValidationRule,
  validators: Record<string, Validator>,
): FieldError | undefined => {
  const fieldError = validateField(data, validationRule, validators);
  if (!fieldError) {
    return undefined;
  }

  return { errorKey: fieldError, validationRule };
};

export const getFieldErrors = (
  data,
  validationRules: ValidationRule[],
  validators: Record<string, Validator>,
): FieldError[] =>
  validationRules
    .map((validationRule) => getFieldError(data, validationRule, validators))
    .filter(Boolean) as FieldError[];
