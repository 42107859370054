import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import styled from 'styled-components';
import FormGroup from './FormGroup';
import { IFormSchema, IFormSchemaField, IInput, IInputGroup } from '../types';
import { FormRendererProvider } from '../contexts/FormRendererContext';

const FormGroupRendererRoot = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

type FormGroupRendererProps = IFormSchemaField & {
  formSchema: IFormSchema;
  formInputs: Record<string, IInput>;
  onInputChange: any;
  onInputFocus: any;
  onInputBlur: any;
};

const FormGroupRenderer: FunctionalComponent<FormGroupRendererProps> = ({
  inputGroups: formSchemaInputGroups,
  formInputs,
  labelKey,
  onInputChange,
  onInputFocus,
  onInputBlur,
  ...otherProps
}) => {
  const inputGroups = useMemo(() => {
    const groups = JSON.parse(
      JSON.stringify(formSchemaInputGroups),
    ) as IInputGroup[];

    groups.forEach((inputGroup) => {
      inputGroup.inputs.forEach((input) => {
        const inputField = formInputs[input.name];
        if (inputField) {
          input.value = inputField.value;
          input.errorMessageKey = inputField.errorMessageKey;
          input.errorMessages = inputField.errorMessages;
          input.errorVisible = inputField.errorVisible;
        }
      });
    });

    return groups;
  }, [formSchemaInputGroups, formInputs]);

  return (
    <FormGroupRendererRoot key={labelKey} {...otherProps}>
      <FormGroup
        formGroupLabel={labelKey}
        inputGroups={inputGroups as IInputGroup[]}
        onInputChange={onInputChange}
        onInputFocus={onInputFocus}
        onInputBlur={onInputBlur}
      />
    </FormGroupRendererRoot>
  );
};

///////////////////////////////////////////
// Form Renderer
///////////////////////////////////////////
type Props = {
  disabled?: boolean;
  formSchema: IFormSchema;
  formInputs: Record<string, IInput>;
  onInputChange: any;
  onInputFocus: any;
  onInputBlur: any;
};

const FormRenderer: FunctionalComponent<Props> = ({
  disabled = false,
  formSchema,
  formInputs,
  onInputChange,
  onInputFocus,
  onInputBlur,
}) => (
  <FormRendererProvider value={{ disabled }}>
    {formSchema?.fields?.map(({ inputGroups, labelKey }) => (
      <FormGroupRenderer
        key={labelKey}
        data-testId={`FormRenderer/FormGroupRenderer/${labelKey}`}
        labelKey={labelKey}
        inputGroups={inputGroups}
        formSchema={formSchema}
        formInputs={formInputs}
        onInputChange={onInputChange}
        onInputFocus={onInputFocus}
        onInputBlur={onInputBlur}
      />
    ))}
  </FormRendererProvider>
);

export default FormRenderer;
