import { useCallback, useState } from 'preact/hooks';
import FormRenderer from '../components/FormRenderer';
import { IFormSchema, UseFormOptions } from '../types';
import useFormState from './useFormState';

const useForm = (formSchema: IFormSchema, options?: UseFormOptions) => {
  const {
    formData,
    submitForm,
    setFormData,
    resetForm,
    resetFormValidation,
    resetFormData,
    onInputChange,
    onInputFocus,
    onInputBlur,
  } = useFormState(formSchema, options);

  const [isDisabled, setDisabled] = useState(false);

  const renderForm = useCallback(
    () => (
      <FormRenderer
        disabled={isDisabled}
        formInputs={formData}
        formSchema={formSchema}
        onInputChange={onInputChange}
        onInputFocus={onInputFocus}
        onInputBlur={onInputBlur}
      />
    ),
    [formData, formSchema, isDisabled, onInputBlur, onInputChange],
  );

  return {
    submitForm,
    setFormData,
    renderForm,
    resetForm,
    resetFormValidation,
    resetFormData,
    setDisabled,

    formData,
    isDisabled,
  };
};

export default useForm;
