import { FunctionalComponent } from 'preact';
import { Ref } from 'preact/hooks';
import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import { UseFormResult } from '@primer-io/shared-library/form';

import CreditCardForm from '../payment-methods/credit-card/CreditCardForm';
import PaymentMethodButtonContainer from '../components/PaymentMethodButtonContainer';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';

type Props = {
  otherPaymentMethodsRef: Ref<HTMLDivElement>;

  cardBillingAddressForm?: UseFormResult;
  cardDescriptionForm?: UseFormResult;
  canShowCardButton: boolean;
  hasCard: boolean;
  hasCreditCardScene: boolean;
  paymentMethodsWithoutCard: [string, BasePaymentMethod][];
};

const CheckoutPaymentScene: FunctionalComponent<Props> = ({
  otherPaymentMethodsRef,
  cardBillingAddressForm,
  cardDescriptionForm,
  canShowCardButton,
  hasCard,
  hasCreditCardScene,
  paymentMethodsWithoutCard,
}) => {
  const store = useCheckoutStore();

  return (
    <div
      ref={otherPaymentMethodsRef}
      id='primer-checkout-other-payment-methods'
    >
      <div>
        {(paymentMethodsWithoutCard.length > 0 ||
          (hasCard && canShowCardButton)) && (
          <PaymentMethodButtonContainer
            paymentMethods={paymentMethodsWithoutCard}
            showCreditCardButton={hasCard && canShowCardButton}
            hasCreditCardSurcharge={
              store.hasCardSurcharge || store.hasSurcharge
            }
          />
        )}
        {hasCard && !canShowCardButton && (
          <CreditCardForm
            cardBillingAddressForm={cardBillingAddressForm}
            cardDescriptionForm={cardDescriptionForm}
          />
        )}
      </div>

      <div
        style={{
          height:
            hasCreditCardScene && !store.hasVault && store.hasAPMs ? 0 : 32,
        }}
      ></div>
    </div>
  );
};

export default CheckoutPaymentScene;
