import styled from 'styled-components';
import Scene from '@primer-sdk-web/scenes/Scene';
import {
  useCheckoutStore,
  useSceneLoaderContext,
} from '@primer-io/shared-library/contexts';
import { useSceneMounting } from '@primer-sdk-web/utils/hooks';
import { Loading } from '@primer-io/shared-library/components';
import { FunctionalComponent } from 'preact';

const Root = styled.div`
  padding-bottom: 32px;
`;

// We may have multiple variants in the future
const VARIANTS = {
  loading: {
    textKey: 'loading',
  },
};

type Props = {
  previousScene?: string;
  scene: string;
  sceneRootId: string;
  backButton?: boolean;

  logoSrc: string;
  logoAlt: string;
};

const PaymentMethodWaitingScreen: FunctionalComponent<Props> = ({
  previousScene,
  scene,
  sceneRootId,
  backButton = true,
  logoSrc,
  logoAlt,
}) => {
  const store = useCheckoutStore();
  const labels = store.getTranslations();
  const { setIsMounted } = useSceneLoaderContext();

  useSceneMounting(() => {
    setIsMounted();
  }, scene);

  const { textKey } = VARIANTS.loading;

  return (
    <Scene
      previousScene={previousScene}
      backButton={backButton}
      sceneRootId={sceneRootId}
      scene={scene}
      dimmable={true}
    >
      <Root className='PrimerCheckout__sceneElement'>
        <Loading text={labels._(textKey)} imgSrc={logoSrc} imgAlt={logoAlt} />
      </Root>
    </Scene>
  );
};

export default PaymentMethodWaitingScreen;
