import window from './window';

interface AwaitableFunction {
  (): Promise<void>;
}

export function nextTick(awaitable: AwaitableFunction): Promise<void> {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      return awaitable().then(resolve).catch(reject);
    }, 1);
  });
}
