import { createContext, FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';
import { ThemeProvider } from 'styled-components';
import { CheckoutStyle } from '../../primer-sdk-web/src/styles';

type Context = { style?: CheckoutStyle };

const StyleContext = createContext<Context>({});

export const Provider: FunctionalComponent<{ value: Context }> = ({
  value,
  children,
}) => (
  <ThemeProvider theme={value}>
    <StyleContext.Provider value={value} children={children} />
  </ThemeProvider>
);
export const useCheckoutStyle = () => useContext(StyleContext);
