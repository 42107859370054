import { FunctionalComponent } from 'preact';
import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import { Alpha3CurrencyCode } from '../types';
import BaseSurchargeContainer from './BaseSurchargeContainer';
import formatAmount from '../utils/formatAmount';

type Props = {
  currencyCode?: Alpha3CurrencyCode;
  surchargeAmount?: number;
  isSurchargeUnknown?: boolean;
};

const SurchargeItem: FunctionalComponent<Props> = ({
  currencyCode,
  surchargeAmount,
  isSurchargeUnknown,
  children,
}) => {
  const store = useCheckoutStore();

  const translations = store.getTranslations();

  ///////////////////////////////////////////
  // Get label
  ///////////////////////////////////////////
  let labelContent = '';
  if (isSurchargeUnknown) {
    labelContent = translations?.additionalCardFees;
  } else if (!surchargeAmount || surchargeAmount === 0) {
    // Surcharge amount is not provided OR surcharge amount is 0
    labelContent = translations?.noAdditionalFees;
  } else if (surchargeAmount && currencyCode) {
    const locale = store.getLocale();
    const localizedSurchargeAmount = formatAmount(
      locale,
      surchargeAmount,
      currencyCode,
    );
    labelContent = `+${localizedSurchargeAmount}`;
  }

  const className = !surchargeAmount
    ? 'PrimerCheckout__label--surcharge-text'
    : 'PrimerCheckout__label--surcharge-amount';

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////
  return (
    <div className='PrimerCheckout__surcharge PrimerCheckout__surchargeItem'>
      <BaseSurchargeContainer className={className} labelContent={labelContent}>
        {children}
      </BaseSurchargeContainer>
    </div>
  );
};

export default SurchargeItem;
