import Set from '@ungap/set';
import equal from 'fast-deep-equal';
import { debounce } from 'throttle-debounce';
import { IBaseStore } from './BaseStore';

export default class StoreChangeEventHandler<T extends IBaseStore> {
  private store: T;

  private getCurrentValueFn: (store: T) => any;

  private listeners: Set<() => void>;

  private currentValue: any;

  private debounceCallListeners: any;

  constructor(store: T, getCurrentValueFn: (store: T) => any) {
    this.listeners = new Set<() => void>();
    this.store = store;
    this.getCurrentValueFn = getCurrentValueFn;

    this.currentValue = getCurrentValueFn(store);

    // Event loop debounce
    this.debounceCallListeners = debounce(1, false, () => this.callListeners());

    this.subscribeToStore();
  }

  private subscribeToStore() {
    this.store.subscribe(() => {
      const newValue = this.getCurrentValueFn(this.store);

      if (!equal(newValue, this.currentValue)) {
        this.currentValue = newValue;
        this.debounceCallListeners();
      }
    });
  }

  private callListeners() {
    this.listeners.forEach((listener) => listener());
  }

  getCurrentValue() {
    return this.currentValue;
  }

  subscribe(listener: () => void) {
    this.listeners.add(listener);
  }

  unsubscribe(listener: () => void) {
    this.listeners.delete(listener);
  }
}
