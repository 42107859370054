import window from './window';
import { base64 } from './base64';

export interface HashInfo {
  sessionId: string;
  name: string;
}

export function encode(data: HashInfo): string {
  return base64.encode(JSON.stringify(data));
}

export function decode(): HashInfo {
  return JSON.parse(
    base64.decode(window.location.hash.substring(1)),
  ) as HashInfo;
}
