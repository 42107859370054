import { useLayoutEffect, useRef } from 'preact/hooks';
import {
  useCheckoutContext,
  useSelector,
} from '@primer-io/shared-library/contexts';
import { Spinner } from '@primer-io/shared-library/components';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../types';

import { useIsFirstRender } from '../utils/hooks';

const canDisplayProcessingIndicator = (
  options:
    | UniversalCheckoutOptions
    | VaultManagerOptions
    | SinglePaymentMethodCheckoutOptions,
) => {
  let canDisplay;
  const displaySubmitButton = options.submitButton?.useBuiltInButton ?? true;
  if (displaySubmitButton) {
    // false by default (already handled by button)
    canDisplay = options.processingIndicator?.visible ?? false;
  } else {
    // true by default (not handled by button)
    canDisplay = options.processingIndicator?.visible ?? true;
  }

  return canDisplay;
};

const ProcessingIndicator = () => {
  const rootRef = useRef(null);
  const { viewUtils, options } = useCheckoutContext();
  const isProcessing = useSelector((s) => s.isProcessing);
  const isFirstRender = useIsFirstRender();

  const canDisplay = canDisplayProcessingIndicator(options);

  useLayoutEffect(() => {
    viewUtils.toggleVisibilityAnimated(
      rootRef.current,
      canDisplay ? isProcessing : false,
      {
        id: 'processing-indicator',
        duration: isFirstRender ? 0 : 500,
      },
    );
  }, [isProcessing]);

  return (
    <div ref={rootRef} className='PrimerCheckout__processingIndicatorOverlay'>
      <div className='PrimerCheckout__processingIndicatorContainer'>
        <Spinner
          color={viewUtils.styleManager.getStyle()?.processingIndicator?.color}
        />
      </div>
    </div>
  );
};

export default ProcessingIndicator;
