import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { PaymentMethodOption } from '../models/ClientSession';
import { Alpha3CurrencyCode, VaultListItem } from '../types';
import { useSurchargedVaultedPaymentMethods } from '../utils/hooks';
import SurchargeItem from './SurchargeItem';
import VaultedPaymentMethod from '../checkout-modules/vault/components/VaultedPaymentMethod/VaultedPaymentMethod';

type Props = {
  vaultedPaymentMethods: VaultListItem[];
  paymentMethodOptions: PaymentMethodOption[];
  currencyCode?: Alpha3CurrencyCode;
};

export interface VaultListItemWithSurcharge {
  surchargeAmount: number;
  vaultedPaymentMethod: VaultListItem;
}

const NoSurchargeContainer = styled.div`
  padding-left: 5;
  padding-right: 5;
`;

const SurchargeVaultedPaymentMethods: FunctionalComponent<Props> = ({
  vaultedPaymentMethods,
  paymentMethodOptions,
  currencyCode,
}) => {
  // Get payment methods which should be rendered with surcharge amount
  const {
    paymentMethodsWithSurchargeAmount,
    paymentMethodsWithoutSurcharge,
  } = useSurchargedVaultedPaymentMethods(
    paymentMethodOptions,
    vaultedPaymentMethods,
  );

  // TODO: Should be informed by the client session
  const showNoAdditionalFees = false;

  const renderPaymentMethodsWithoutSurcharge = () => {
    if (showNoAdditionalFees) {
      return (
        <SurchargeItem surchargeAmount={0}>
          {paymentMethodsWithoutSurcharge.map((vaultedItem) => (
            <VaultedPaymentMethod
              key={vaultedItem.id}
              vaultedItem={vaultedItem}
            />
          ))}
        </SurchargeItem>
      );
    }
    return (
      <NoSurchargeContainer>
        {paymentMethodsWithoutSurcharge.map((vaultedItem) => (
          <VaultedPaymentMethod
            key={vaultedItem.id}
            vaultedItem={vaultedItem}
          />
        ))}
      </NoSurchargeContainer>
    );
  };

  return (
    <div>
      {paymentMethodsWithoutSurcharge.length > 0 &&
        renderPaymentMethodsWithoutSurcharge()}
      {paymentMethodsWithSurchargeAmount.map((vaultedItem) => (
        <SurchargeItem
          key={vaultedItem.vaultedPaymentMethod.id}
          currencyCode={currencyCode}
          surchargeAmount={vaultedItem.surchargeAmount}
        >
          <VaultedPaymentMethod
            vaultedItem={vaultedItem.vaultedPaymentMethod}
          />
        </SurchargeItem>
      ))}
    </div>
  );
};

export default SurchargeVaultedPaymentMethods;
