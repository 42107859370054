import { Api } from '../../core/Api';
import {
  PaymentInstrumentType,
  VaultData,
  ThreeDSAuthenticationData,
} from '../../types';

export interface IVaultedPaymentMethod<T, U extends PaymentInstrumentType> {
  id: string;
  analyticsId: string;
  paymentInstrumentData: T;
  paymentInstrumentType: U;
  threeDSecureAuthentication: Nullable<ThreeDSAuthenticationData>;
  vaultData: Nullable<VaultData>;
  userDescription?: string;
}

export type VaultedPaymentMethod = IVaultedPaymentMethod<any, any>;
interface VaultServices {
  api: Api;
}

export class Vault {
  private api: Api;

  constructor(services: VaultServices) {
    this.api = services.api;
  }

  public async fetch(): Promise<VaultedPaymentMethod[]> {
    const url = '/payment-instruments';

    const response = await this.api.get<{ data: VaultedPaymentMethod[] }>(url, {
      apiVersion: '2021-12-10',
    });

    if (!response.data) {
      return [];
    }

    return response.data.data || [];
  }

  async delete(id: Nullable<string>): Promise<boolean> {
    if (!id) {
      return false;
    }

    const response = await this.api.delete(`/payment-instruments/${id}/vault`);

    return !response.error;
  }
}
