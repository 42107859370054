import { keyframes } from 'styled-components';

export const Dash = keyframes`
0% {
   stroke-dasharray: 1, 150;
   stroke-dashoffset: 0;
 }
 100% {
   stroke-dasharray: 90, 150;
   stroke-dashoffset: -35;
 }
 100% {
   stroke-dasharray: 90, 150;
   stroke-dashoffset: -124;
 }
`;
