import { PaymentMethodToken } from '../types';
import { Api, APIResponse } from './Api';

export interface PaymentMethodTokenizationHandlerRequestOptions {
  maxAttempts?: number;
  apiVersion?: string;
}

type Context = {
  api: Api;
};

export type PaymentMethodTokenizer = (
  body: any,
  requestOptions?: PaymentMethodTokenizationHandlerRequestOptions,
) => Promise<APIResponse<PaymentMethodToken>>;

export const PaymentMethodTokenizationHandler = {
  create(context: Context) {
    const tokenizePaymentMethod: PaymentMethodTokenizer = async (
      body: any,
      requestOptions?: PaymentMethodTokenizationHandlerRequestOptions,
    ) => {
      return context.api.post<unknown, PaymentMethodToken>(
        '/payment-instruments',
        body,
        {
          apiVersion: '2021-12-10',
          ...requestOptions,
        },
      );
    };
    return {
      tokenizePaymentMethod,
    };
  },
};
