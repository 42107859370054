import {
  addEventListener,
  UnsubscribeCallback,
} from '../utils/addEventListener';
import { ElementID } from '../enums/Checkout';
import { resolveElement } from '../utils/resolveElement';

export class OffClickHandler {
  private ids: Array<ElementID>;

  private unsubscribe: Nullable<UnsubscribeCallback>;

  constructor(ids: ElementID | Array<ElementID>) {
    this.ids = Array.isArray(ids) ? ids : [ids];
    this.unsubscribe = null;
  }

  listen(callback: () => void): void {
    const eventListener = (evt: MouseEvent) => {
      const node = evt.target;

      if (!(node instanceof Node)) {
        return;
      }

      for (let i = 0; i < this.ids.length; i += 1) {
        const id = this.ids[i];
        const parent = resolveElement(id);

        if (parent == null) {
          return;
        }

        if (parent === evt.target || parent.contains(node)) {
          return;
        }
      }

      this.clear();

      callback();
    };

    this.unsubscribe = addEventListener(
      document.body,
      'mousedown',
      eventListener,
    );
  }

  clear(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }
}
