import { useLayoutEffect } from 'preact/hooks';
import {
  useSceneLoaderContext,
  useCheckoutStore,
  useCheckoutContext,
} from '@primer-io/shared-library/contexts';
import { SceneEnum } from '../../enums/Checkout';
import { Icons } from '../../assets/icons/Icons';
import { useSceneMounting } from '../../utils/hooks';
import {
  UniversalCheckoutOptions,
  CheckSuccessScreenOptions,
} from '../../types';
import getLabel from '../../utils/getLabel';

const SCENE = SceneEnum.SUCCESS_CHECK;

export const sceneRootId = 'primer-checkout-scene-success-check';

const CheckSuccessScene = () => {
  const store = useCheckoutStore();
  const { options } = useCheckoutContext();
  const { setIsMounted } = useSceneLoaderContext();

  useLayoutEffect(() => {
    setIsMounted();
  }, []);

  useSceneMounting(() => {
    store.setSubmitButtonVisible(false);
  }, SCENE);

  const { title: successScreenTitle } =
    ((options as UniversalCheckoutOptions)
      .successScreen as CheckSuccessScreenOptions) ?? {};

  let successTitleLabel: string | undefined;
  if (successScreenTitle) {
    successTitleLabel = getLabel(successScreenTitle, {
      locale: store.getState().locale,
    });
  } else {
    successTitleLabel = store.getTranslations().checkSuccessTitle;
  }

  return (
    <div
      id={sceneRootId}
      className='PrimerCheckout__scene PrimerCheckout--exited PrimerCheckout__check'
    >
      <img
        src={Icons.successCheck}
        alt='success check mark'
        className='PrimerCheckout__checkMarkIcon'
      />
      <div className='PrimerCheckout__label'>{successTitleLabel}</div>
    </div>
  );
};

export default CheckSuccessScene;
