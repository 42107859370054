import {
  useCheckoutModule,
  useCheckoutStore,
  useStoreSelector,
} from '@primer-io/shared-library/contexts';
import {
  useForm,
  buildAndFilterFormSchema,
  IFormFieldDescription,
} from '@primer-io/shared-library/form';
import { useEffect, useMemo } from 'preact/hooks';
import {
  BillingAddressStore,
  BillingAddressStoreSelector,
} from './BillingAddressStore';

const billingAddressField: IFormFieldDescription = {
  formLabel: 'billingAddress',
  inputGroups: [
    [{ name: 'countryCode', type: 'countrySelect' }],
    [{ name: 'firstName' }, { name: 'lastName' }],
    [{ name: 'addressLine1' }],
    [{ name: 'addressLine2' }],
    [{ name: 'postalCode', halfWidth: true }, { name: 'city' }],
    [{ name: 'state' }],
  ],
};

export const useBillingAddressForm = () => {
  const store = useCheckoutStore();
  const billingAddress = useCheckoutModule<BillingAddressStore>(
    'BILLING_ADDRESS',
  );

  if (!billingAddress) {
    return undefined;
  }

  if (!billingAddress.fields.postalCode) {
    return undefined;
  }

  const isSendingBillingAddress = useStoreSelector(
    billingAddress,
    BillingAddressStoreSelector.getIsSendingBillingAddress,
  );

  const fields = billingAddress?.fields;

  const formSchema = useMemo(() => {
    return buildAndFilterFormSchema([billingAddressField], fields);
  }, []);

  const form = useForm(formSchema);

  useEffect(() => {
    const formHasValue = !!Object.values(form.formData).find(
      (input) => input.value,
    );
    if (store.selectedVault && formHasValue) {
      store.selectVault(null);
    }
    billingAddress.setBillingAddressValue(form.formData);
  }, [
    form.formData.postalCode?.value,
    form.formData.firstName?.value,
    form.formData.lastName?.value,
    form.formData.addressLine1?.value,
    form.formData.addressLine2?.value,
    form.formData.city?.value,
    form.formData.countryCode?.value,
  ]);

  useEffect(() => {
    const formIsActive = !!Object.values(form.formData).find(
      (input) => input.focused && input.dirty,
    );

    if (store.selectedVault && formIsActive) {
      store.selectVault(null);
    }
  }, [
    form.formData.postalCode?.focused,
    form.formData.firstName?.focused,
    form.formData.lastName?.focused,
    form.formData.addressLine1?.focused,
    form.formData.addressLine2?.focused,
    form.formData.city?.focused,
    form.formData.countryCode?.focused,
  ]);

  if (!billingAddress) {
    return undefined;
  }

  if (!billingAddress.fields.postalCode) {
    return undefined;
  }

  return { ...form, isSendingBillingAddress };
};
