const getErrorMessages = (inputGroups) =>
  inputGroups.flatMap((inputGroup) =>
    inputGroup.inputs.flatMap((input) =>
      input.errorMessages?.flatMap((errorMessage) => ({
        errorMessage,
        visible: input?.errorVisible,
      })),
    ),
  );

export default getErrorMessages;
