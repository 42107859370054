import window from './window';

type ElementOrSelector = Maybe<string | Element>;

export type ElementSelectorOrFunction = Maybe<
  ElementOrSelector | Callable<[], ElementOrSelector>
>;

export function locateElement(
  elementSelectorOrFunction: ElementSelectorOrFunction,
): Nullable<Element> {
  if (typeof elementSelectorOrFunction === 'function') {
    return locateElement(elementSelectorOrFunction());
  }

  if (typeof elementSelectorOrFunction === 'string') {
    return window.document.querySelector(elementSelectorOrFunction);
  }

  if (elementSelectorOrFunction == null) {
    return null;
  }

  return elementSelectorOrFunction;
}
