export enum HTMLElementEventType {
  CLICK = 'click',
  FOCUS = 'focus',
  BLUR = 'blur',
  INPUT = 'input',
  KEYDOWN = 'keydown',
  KEYUP = 'keyup',
  LOAD = 'load',
  PASTE = 'paste',
}
