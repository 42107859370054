import { TranslationUnit } from '@primer-sdk-web/utils/i18n/Translation';
import toCamelCase from '@primer-sdk-web/utils/toCamelCase';
import { FieldError } from '../../types';

const mapInputErrorMessages = (
  fieldErrors: FieldError[],
  errorMessageKey: string,
  labels: TranslationUnit,
) =>
  fieldErrors.map((error) => {
    const key = `${errorMessageKey}${
      error.errorKey.charAt(0) + toCamelCase(error.errorKey).slice(1)
    }`;

    return labels[key];
  });

export default mapInputErrorMessages;
