import {
  createWithStyle,
  getTextStyle,
} from '@primer-io/shared-library/components';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const withVaultTitleStyle = createWithStyle((style) => style?.vaultTitle);

const Root = styled.div`
  ${withVaultTitleStyle((style) => getTextStyle(style))}
`;

const VaultTitle: FunctionalComponent = ({ children }) => {
  return (
    <Root className='PrimerCheckout__label PrimerCheckout__label--muted PrimerCheckout__savedPaymentMethods__label'>
      {children}
    </Root>
  );
};

export default VaultTitle;
