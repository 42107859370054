import { AlertOctagon, X } from 'preact-feather';
import visaIcon from './card-visa.png';
import mastercardIcon from './card-mastercard.png';
import amexIcon from './card-american-express.png';
import jcbIcon from './card-jcb.png';
import discoverIcon from './card-discover.png';
import genericIcon from './card-generic.svg';
import paypalIcon from './paypal.png';
import successCheckIcon from './success-check.svg';

export const Icons = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  americanExpress: amexIcon,
  jcb: jcbIcon,
  discover: discoverIcon,
  genericCard: genericIcon,
  paypal: paypalIcon,
  successCheck: successCheckIcon,

  error: <AlertOctagon className='icon' />,
  x: <X className='icon' />,
};
