export const errors = {
  SETUP: {
    message:
      '3DS is not configured for your primer account. See our guide on getting started with 3DS.',
    code: '3DS_SETUP_ERROR',
  },
  CONFIGURATION: {
    message: '3DS failed during setup phase.',
    code: '3DS_CONFIGURATION_ERROR',
  },
  API: {
    message: 'Failed to connect to the Primer API',
    code: '3DS_API_ERROR',
  },
  AUTHENTICATION: {
    message: 'Failed to negotiate 3DS authentication',
    code: '3DS_AUTHENTICATION_ERROR',
  },
};
