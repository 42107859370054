import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import { PaymentMethodOption } from '../models/ClientSession';
import { Alpha3CurrencyCode } from '../types';
import { useSurchargedPaymentMethods } from '../utils/hooks';
import PaymentMethodButton from './PaymentMethodButton';
import SurchargeItem from './SurchargeItem';

type Props = {
  paymentMethods: [string, BasePaymentMethod][];
  paymentMethodOptions: PaymentMethodOption[];
  currencyCode?: Alpha3CurrencyCode;
};

export interface PaymentMethodWithSurcharge {
  paymentMethod: [string, BasePaymentMethod][];
  surchargeAmount: number;
}

const NoSurchargeContainer = styled.div`
  padding-left: 5;
  padding-right: 5;
`;

const SurchargePaymentMethods: FunctionalComponent<Props> = ({
  paymentMethods,
  paymentMethodOptions,
  currencyCode,
}) => {
  const {
    paymentMethodsWithoutSurcharge,
    paymentMethodsWithSurcharge,
  } = useSurchargedPaymentMethods(paymentMethodOptions, paymentMethods);

  // TODO: Should be informed by the client session
  const showNoAdditionalFees = false;

  const renderPaymentMethodsWithoutSurcharge = () => {
    if (showNoAdditionalFees) {
      return (
        <SurchargeItem surchargeAmount={0}>
          {paymentMethodsWithoutSurcharge.map(([key, paymentMethod]) => (
            <PaymentMethodButton
              key={key}
              id={key}
              paymentMethod={paymentMethod}
            />
          ))}
        </SurchargeItem>
      );
    }
    return (
      <NoSurchargeContainer>
        {paymentMethodsWithoutSurcharge.map(([key, paymentMethod]) => (
          <PaymentMethodButton
            key={key}
            id={key}
            paymentMethod={paymentMethod}
          />
        ))}
      </NoSurchargeContainer>
    );
  };

  return (
    <div>
      {renderPaymentMethodsWithoutSurcharge()}
      {paymentMethodsWithSurcharge.map((item: PaymentMethodWithSurcharge) =>
        item.paymentMethod.map(([key, paymentMethod]) => (
          <SurchargeItem
            key={key}
            currencyCode={currencyCode}
            surchargeAmount={item.surchargeAmount}
          >
            <PaymentMethodButton id={key} paymentMethod={paymentMethod} />
          </SurchargeItem>
        )),
      )}
    </div>
  );
};

export default SurchargePaymentMethods;
