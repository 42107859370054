import { Label, SupportedLocale } from '../types';

const getLabel = <T extends Record<string, unknown>>(
  label: Label<T> | undefined,
  options: { locale: SupportedLocale } & T,
): string | undefined => {
  if (typeof label === 'string') {
    return label;
  }

  if (typeof label === 'function') {
    return label(options);
  }

  return undefined;
};

export default getLabel;
