import {
  createWithStyle,
  xsBreakpoint,
} from '@primer-io/shared-library/components';
import { css } from 'styled-components';

const withSeparatorStyle = createWithStyle((style) => style?.separator);

export const TextDividerStyle = css`
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    display: block;
    content: '';
    flex: 1;
    height: 1px;
    min-width: 16px;

    ${withSeparatorStyle((style) => ({ background: style?.color }))}
  }

  &::before {
    margin-right: 8px;
    ${xsBreakpoint} {
      margin-right: 2px;
    }
  }

  &::after {
    margin-left: 8px;
    ${xsBreakpoint} {
      margin-left: 2px;
    }
  }
`;
