import {
  LengthValidationRule,
  NumericValidationRule,
  RegexValidationRule,
  ValidationRule,
} from '../../types';

export const DEFAULT_VALIDATORS = {
  REQUIRED: (data, rule: ValidationRule) => {
    if (!data) {
      return 'REQUIRED';
    }

    return undefined;
  },

  LENGTH: (data, rule: ValidationRule) => {
    const str = data as string;
    const validationRule = rule as LengthValidationRule;
    if (
      str.length === validationRule.maxLength ||
      str.length === validationRule.minLength
    ) {
      return undefined;
    }

    if (validationRule.minLength && str.length < validationRule.minLength) {
      return 'TOO_SHORT';
    }

    if (validationRule.maxLength && str.length > validationRule.maxLength) {
      return 'TOO_LONG';
    }

    return undefined;
  },

  NUMERIC: (data, rule: ValidationRule) => {
    const str = data as string;
    const validationRule = rule as NumericValidationRule;

    if (/[a-zA-Z]/g.test(str) && validationRule.type === 'NUMERIC') {
      return 'NUMERIC';
    }

    return undefined;
  },

  // Test validator. Could keep if necessary
  EMAIL: (data, rule: ValidationRule) => {
    const str = data as string;
    const validationRule = rule as any;

    if (validationRule.type === 'EMAIL') {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const isValidEmail = regex.test(str);
      return isValidEmail ? undefined : 'INVALID';
    }

    return undefined;
  },

  REGEX: (data, rule: ValidationRule) => {
    const str = data as string;
    const validationRule = rule as RegexValidationRule;

    const regex = new RegExp(validationRule.regex, validationRule.flags);
    if (!regex.test(str)) {
      return 'INVALID';
    }

    return undefined;
  },
};

export default DEFAULT_VALIDATORS;
