export const Warnings = {
  orderNeverProvided:
    'The orderDetails option must be provided in order to use {paymentMethod}',
  orderMissingInClientSession:
    'The "order" option must be provided in the client session in order to use {paymentMethod}',
  bothOptionsAndClientSession:
    'Information relating to the order has been provided in both the client session request and as checkout options. Provided client session information will be favored.',
  countryCodeNotProvided:
    'The countryCode option must be provided in order to use {paymentMethod}',
  currencyCodeNotProvided:
    'The currencyCode option must be provided in order to use {paymentMethod}',
  bothPurchaseInfoAndOrderDetails:
    'both purchaseInfo and orderDetails have been provided, orderDetails will take preference',
  setClientTokenWithoutToken:
    'setClientToken() was called without a client-token',
  noTotalAmountInClientSession:
    'No total amount has been detected in the client session. Please provide an amount and/or line-items when requesting a client session',
  noCurrencyInClientSession:
    'No currency code has been detected in the client session. Please provide a currency code when requesting a client session.',
  applePayVersionNotSupported:
    'Device operating system version does not support Apple Pay',
};

export const DeprecationWarnings = {
  optionSetter:
    'Passing {setterType} as a checkout option has been deprecated. Please pass this data when creating a client session with POST /client-session',
  tokenize: 'tokenize() has been deprecated, please use submit() instead',
  validate:
    'validate() has been deprecated and only works for the credit card form, please use submit() instead',
  setPurchaseInfo:
    'setPurchaseInfo has been deprecated. Please pass this data when creating a client session with POST /client-session',
};
