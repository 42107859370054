import {
  IBaseStore,
  IBaseState,
  BaseStore,
} from '@primer-sdk-web/store/BaseStore';
import {
  useCheckoutStore,
  useStoreSelector,
  useSelector,
} from '@primer-io/shared-library/contexts';

export const usePaymentMethodStore = <Store extends IBaseStore>(
  paymentMethodType: string,
): Store => {
  const store = useCheckoutStore();
  const paymentMethod = store.getPaymentMethodStoreWithType<Store>(
    paymentMethodType,
  );
  return paymentMethod as Store;
};

export const useCheckoutModule = <Store extends IBaseStore>(
  checkoutModuleType: string,
): Store | undefined => {
  const store = useCheckoutStore();
  useSelector((s) => s.checkoutModules);
  const checkoutModule = store.getCheckoutModuleWithType(checkoutModuleType);
  return checkoutModule as Store;
};

export const usePaymentMethodStoreSelector = <
  State extends IBaseState,
  Store extends BaseStore<State>,
  Selected
>(
  paymentMethodType: string,
  selector: (state: State) => Selected,
): Selected => {
  const store = usePaymentMethodStore(paymentMethodType) as Store;
  return useStoreSelector(store, selector);
};

export const useStore = <Store extends IBaseStore>(id: string): Store => {
  const store = useCheckoutStore();
  return store.getStore(id) as Store;
};

export const useLanguageCode = () => {
  const store = useCheckoutStore();
  return store.getLocale().split('-')[0];
};
