import { FunctionalComponent } from 'preact';
import { Icons } from '../../assets/icons/Icons';

const CardIconData = {
  'visa': {
    icon: Icons.visa,
    alt: 'Visa Logo',
  },
  'mastercard': {
    icon: Icons.mastercard,
    alt: 'Mastercard Logo',
  },
  'american-express': {
    icon: Icons.americanExpress,
    alt: 'American Express Logo',
  },
  'jcb': {
    icon: Icons.jcb,
    alt: 'JCB Logo',
  },
  'discover': {
    icon: Icons.discover,
    alt: 'Discover Logo',
  },
};

const defaultCardIconData = {
  icon: Icons.genericCard,
  alt: 'Card Logo',
};

type CardIconProps = {
  cardType?: string;
  isHidden?: boolean;
  showError?: boolean;
};

export const CardIcon: FunctionalComponent<CardIconProps> = ({
  cardType = 'default',
  isHidden,
  showError = false,
}) => {
  const { icon, alt } = CardIconData[cardType] ?? defaultCardIconData;

  return (
    <div
      className={`PrimerCheckout__cardType ${
        isHidden ? 'PrimerCheckout--hidden' : ''
      } ${showError ? 'PrimerCheckout--error' : ''}`}
    >
      <img src={icon} alt={alt} />
    </div>
  );
};
