import { noop } from '../utils/noop';

export default function normalizeOptions(options) {
  return {
    ...options,
    locale: normalizeLocale(options.locale),
    allowedCardNetworks: options.allowedCardNetworks,
    logWarnings:
      typeof options.logWarnings === 'boolean' ? options.logWarnings : false,
    mountImmediately:
      typeof options.mountImmediately === 'boolean'
        ? options.mountImmediately
        : true,
    vaultOnly: options.vaultOnly || false,
    onTokenizeDidNotStart: options.onTokenizeDidNotStart || noop,
    onTokenizeStart: options.onTokenizeStart || noop,
    onTokenizeProgress: options.onTokenizeProgress || noop,
    onTokenizeSuccess: options.onTokenizeSuccess || noop,
    onTokenizeError: options.onTokenizeError || noop,
    onTokenizeEnd: options.onTokenizeEnd || noop,
  };
}

function normalizeLocale(locale) {
  if (!locale) {
    return undefined;
  }

  return locale.replace('_', '-');
}
