import window from '../utils/window';
import { locateElement } from '../utils/dom';

export default class ThreeDSecureUIFramework {
  constructor(options) {
    this.options = options;
  }

  getParent() {
    return locateElement(this.options.container) || window.document.body;
  }

  render(template, screenSize) {
    let el = window.document.createElement('div');

    el.innerHTML = template;

    const frame = el.firstElementChild;

    const parent = this.getParent();

    const body = window.document.getElementById('primer-checkout-3ds-modal');

    if (frame) {
      parent.appendChild(frame);
      frame.setAttribute('width', '100%');
      frame.setAttribute('height', '100%');

      if (body) {
        body.style.height = `min(calc(100vh - 16px), ${screenSize.height}px)`;
        body.style.width = `min(calc(100vw - 16px), ${screenSize.width}px)`;
      }
    }

    el = null;
  }

  renderSuppressed(template) {
    const el = window.document.createElement('div');

    el.style.display = 'none';
    el.style.position = 'absolute';

    window.document.body.appendChild(el);
    el.innerHTML = template;
  }
}
