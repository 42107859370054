import { ThreeDSecureStatus } from '../enums/Tokenization';
import { PrimerClientError } from '../errors';
import { PaymentMethodToken } from '../types';

export function mergeTokenWithSynthetic3DSError(
  token: PaymentMethodToken,
  error: PrimerClientError,
): PaymentMethodToken {
  return {
    ...token,
    threeDSecureAuthentication: {
      challengeIssued: false,
      protocolVersion: '',
      responseCode: ThreeDSecureStatus.SKIPPED,
      reasonCode: 'CLIENT_ERROR',
      reasonText: error.message,
    },
  };
}
