import { IFrameMessage, IFrameMessagePayload } from './IFrameMessage';
import window from '../utils/window';
import { addEventListener } from '../utils/addEventListener';

export interface Observer {
  (event: IFrameMessagePayload): void;
}

export class Observable {
  private observers: Record<string, Observer[]>;

  constructor() {
    this.observers = {};

    addEventListener((window as unknown) as HTMLElement, 'message', (e) => {
      const evt = e as MessageEvent;
      const event = IFrameMessage.unmarshal(evt.data);

      if (event == null) {
        return;
      }

      this.onEvent(event);
      this.notify(event);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEvent(event: IFrameMessagePayload): void {
    /* Not Implemented */
  }

  on(type: string, observer: Observer): void {
    if (typeof this.observers[type] === 'undefined') {
      this.observers[type] = [];
    }

    this.observers[type].push(observer);
  }

  once(type: string, observer: Observer): void {
    const wrapped = (evt: IFrameMessagePayload) => {
      observer(evt);
      this.observers[type].splice(this.observers[type].indexOf(wrapped), 1);
    };

    this.on(type, wrapped);
  }

  notify(event: IFrameMessagePayload): void {
    const observers = this.observers[event.type] || [];
    observers.forEach((fn) => fn(event));
  }
}
