import Spinner from '@primer-io/shared-library/components/Spinner';

export const sceneRootId = 'primer-checkout-scene-loading';

const LoadingScene = () => {
  return (
    <div id={sceneRootId} className='PrimerCheckout__scene'>
      <Spinner />
    </div>
  );
};

export default LoadingScene;
