import EventTarget from '@ungap/event-target';
import { isMobile } from '../../utils/browser';

import { getWindowFeatures, getCenteredPopupWindowOptions } from './utils';

type PopupServiceOpenOptions = {
  width: number;
  height: number;
  url?: string;
  popupName?: string;
};

const CLOSED_POLL_INTERVAL = 100;

export const canFocusOnPopup = () => !isMobile();

export class PopupService extends EventTarget {
  private isOpen: boolean;

  private popupWindow: Window | undefined;

  constructor() {
    super();
    this.popupWindow = undefined;
    this.isOpen = false;
  }

  getIsOpen() {
    return this.isOpen;
  }

  /***
   * Opens a blank popup window centered in the parent window
   *
   */
  open({ width, height, url, popupName }: PopupServiceOpenOptions) {
    if (this.isOpen || this.popupWindow) {
      return;
    }

    // Get options to center the popup in the window
    const windowOptions = getCenteredPopupWindowOptions({
      popup: {
        width,
        height,
      },
      parent: {
        top: window.screenTop,
        left: window.screenLeft,
        width: window.outerWidth,
        height: window.outerHeight,
      },
    });

    // window.open needs a string as an option
    const windowFeatures = getWindowFeatures(windowOptions);

    const popupWindow = window.open(url ?? '', popupName, windowFeatures);
    if (!popupWindow) {
      return;
    }

    this.isOpen = true;
    this.popupWindow = popupWindow;

    this.listenToParentWindowClose();
    this.listenToPopupWindowClose();
  }

  setContent(content: string) {
    if (!this.isOpen || !this.popupWindow) {
      return;
    }

    this.popupWindow.document.write(content);
  }

  setUrl(url: string) {
    if (!this.isOpen || !this.popupWindow) {
      return;
    }

    this.popupWindow.location.replace(url);
  }

  close() {
    if (!this.isOpen || !this.popupWindow) {
      return;
    }

    this.popupWindow.close();

    // Call handle child now to avoid the time for the poll
    this.handleChildClose();
  }

  focus() {
    if (!this.popupWindow) {
      return;
    }

    this.popupWindow?.focus();
    //TODO: check that focus worked
  }

  private listenToParentWindowClose() {
    window.addEventListener('beforeunload', this.handleParentClose.bind(this));
  }

  private listenToPopupWindowClose() {
    // popupWindow most likely comes from another domain
    // In this case, the browser prevents us from adding an event listener to popupWindow
    // It is however possible to regularly poll popupWindow.closed to determine if the popupWindow is still open.

    const timer = setInterval(() => {
      if (this.popupWindow?.closed) {
        clearInterval(timer);
        this.handleChildClose();
      }
    }, CLOSED_POLL_INTERVAL);
  }

  private handleParentClose() {
    this.close();
  }

  private handleChildClose() {
    if (!this.isOpen || !this.popupWindow) {
      return;
    }

    this.isOpen = false;
    this.popupWindow = undefined;
    this.dispatchEvent(new Event('close'));
  }
}

export const createPopupService = () => new PopupService();
