import { IBaseState, BaseStore } from '../../store/BaseStore';

interface CardInformationState extends IBaseState {
  isShowingCardholderName: boolean;
}

const defaultState: CardInformationState = {
  isShowingCardholderName: true,
};

export const CardInformationStoreSelector = {
  getIsShowingCardholderName: (s: CardInformationState) =>
    s.isShowingCardholderName,
};

export class CardInformationStore extends BaseStore<CardInformationState> {
  static type = 'CARD_INFORMATION';

  get type() {
    return CardInformationStore.type;
  }

  showCardholderName(show: boolean) {
    this.produceState((draft) => {
      draft.isShowingCardholderName = show;
    });
  }

  get isShowingCardholderName() {
    return CardInformationStoreSelector.getIsShowingCardholderName(
      this.getState(),
    );
  }
}

const createCardInformationStore = () => new CardInformationStore(defaultState);
export default createCardInformationStore;
