import { useLayoutEffect, useMemo } from 'preact/hooks';
import {
  useSceneLoaderContext,
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import {
  formatVaultedToken,
  paymentMethodTokenToVaultedPaymentMethod,
} from '../../checkout-modules/vault/utils';
import PaymentMethodTokenItem from '../../components/PaymentMethodTokenItem';
import { SceneEnum } from '../../enums/Checkout';

import { useSceneMounting } from '../../utils/hooks';

const SCENE = SceneEnum.SUCCESS_PAYMENT_METHOD;

export const sceneRootId = 'primer-checkout-scene-success-payment-method';

const PaymentMethodSuccessScene = () => {
  const store = useCheckoutStore();
  const { setIsMounted } = useSceneLoaderContext();
  const currentToken = useSelector((s) => s.currentToken);

  const item = useMemo(() => {
    if (!currentToken) {
      return null;
    }

    return formatVaultedToken(
      paymentMethodTokenToVaultedPaymentMethod(currentToken),
    );
  }, [currentToken]);

  useLayoutEffect(() => {
    setIsMounted();
  }, []);

  useSceneMounting(() => {
    store.setSubmitButtonVisible(false);
  }, SCENE);

  return (
    <div
      id={sceneRootId}
      className='PrimerCheckout__scene PrimerCheckout--exited'
      style={{
        padding: '0px 16px',
      }}
    >
      {item && <PaymentMethodTokenItem vaultedItem={item} disabled selected />}
    </div>
  );
};

export default PaymentMethodSuccessScene;
