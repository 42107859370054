import { getSentryClient } from './SentryLoader';
import { Environment } from '../utils/Environment';

// Needs to be loaded once for the whole library
let loaded = false;

const allowUrls = [
  'https://assets.primer.io/',
  'https://sdk.primer.io',
  'https://sdk.dev.primer.io',
];

const getClient = async () => {
  return getSentryClient({
    dsn: Environment.get('SENTRY_DSN'),
    allowUrls,
    debug: true,
    release: `primer-io-checkout-web@${Environment.get('PRIMER_SDK_VERSION')}`,
  });
};

const captureException = (error: Error) => {
  // Sentry is lazy loaded when the first error occurs
  getClient().then((client) => client.captureException(error));
};

export const initGlobalErrorMonitoring = () => {
  if (loaded) {
    return;
  }
  loaded = true;

  window.addEventListener('error', (event) => {
    // We don't want to catch "Script error." errors, because they may come
    // from the website the SDK is integrated in.
    if (
      event.message === 'Script error.' ||
      !event.filename ||
      event.lineno === 0
    ) {
      return;
    }

    // We need to do the "allowUrls" check here
    // We can't expect our Sentry to do it - if multiple Sentry's are there, they will all take the event
    const isAllowed = !!allowUrls.find((url) => event.filename.startsWith(url));
    if (!isAllowed) {
      return;
    }

    captureException(event.error);
  });
};

export interface ErrorMonitoring {
  captureException(error: Error);
}

export const createErrorMonitoring = (): ErrorMonitoring => {
  return {
    captureException,
  };
};
