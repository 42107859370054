import createUnistore, {
  Action,
  Listener,
  Store as IStore,
  Unsubscribe,
} from 'unistore';

import produce, { Draft } from 'immer';
import StoreChangeEventHandler from './StoreChangeEventHandler';

export interface NodeLike {
  id: string;
}

export enum SceneStage {
  Init = 'init',
  Mounting = 'mounting',
  Entering = 'entering',
  Entered = 'entered',
  Exiting = 'exiting',
  Exited = 'exited',
}

export type SceneState = {
  stage: SceneStage;
  promise: {
    resolve: () => void;
    reject: () => void;
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseState extends Record<string, any> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseStore {
  getState(): IBaseState;

  subscribe(listener);
}

export type StateSelector<T extends IBaseState> = (store: T) => any;

export class BaseStore<K extends IBaseState> implements IBaseStore {
  // Used to keep track of the type of the state for some typescript magic
  readonly stateType: K;

  readonly state: IStore<K>;

  readonly subStores: Record<string, IBaseStore>;

  private eventHandlers: Record<string, any>;

  constructor(defaultState: K) {
    this.state = createUnistore(defaultState);
    this.subStores = {};
    this.eventHandlers = {};
  }

  setState<U extends keyof K>(
    update: Pick<K, U>,
    overwrite?: boolean,
    action?: Action<K>,
  ): void {
    this.state.setState(update, overwrite, action);
  }

  produceState(recipe: (draft: Draft<K>) => Pick<K, never> | void): void {
    this.state.setState(produce(this.getState(), recipe));
  }

  subscribe(f: Listener<K>): Unsubscribe {
    return this.state.subscribe(f);
  }

  unsubscribe(f: Listener<K>): void {
    return this.state.unsubscribe(f);
  }

  getState(): K {
    return this.state.getState();
  }

  ///////////////////////////////////////////
  // Substore
  ///////////////////////////////////////////

  registerStore<U extends IBaseStore>(id: string, store: U) {
    if (this.subStores[id]) {
      return;
    }

    this.subStores[id] = store;

    // Compose states
    this.produceState((draft: Draft<IBaseState>) => {
      draft[id] = store.getState();
    });

    store.subscribe(() => {
      this.produceState((draft: Draft<IBaseState>) => {
        draft[id] = store.getState();
      });
    });
  }

  getStore<U extends IBaseStore>(id: string): U | undefined {
    return this.subStores[id] as U;
  }

  ///////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////

  protected registerEventHandler<T extends IBaseStore>(
    eventId: string,
    eventHandler: (store: T) => any,
  ) {
    this.eventHandlers[eventId] = new StoreChangeEventHandler<T>(
      (this as unknown) as T,
      eventHandler,
    );
  }

  protected registerEventHandlers<T extends IBaseStore>(
    eventHandlers: Record<string, (store: T) => any>,
  ) {
    Object.entries(eventHandlers).forEach(([key, value]) =>
      this.registerEventHandler(key, value),
    );
  }

  on(eventName: string, listener: () => void) {
    const eventHandler = this.eventHandlers[eventName];
    if (!eventHandler) {
      return;
    }

    eventHandler.subscribe(listener);
  }

  off(eventName: string, listener: () => void) {
    const eventHandler = this.eventHandlers[eventName];
    if (!eventHandler) {
      return;
    }

    eventHandler.unsubscribe(listener);
  }
}
