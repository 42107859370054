import { FunctionalComponent } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import styled from 'styled-components';
import { animationCurve } from '@primer-io/shared-library/components';
import { usePresence } from '../../utils/AnimatePresence/use-presence';
import { AnimatePresence } from '../../utils/AnimatePresence';
import { CardIcon } from './CardIcons';

const FADE_DURATION = 300;

const FadeRoot = styled.div<{ status: 'init' | 'enter' | 'exit' }>`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  transition: all ${FADE_DURATION}ms ${animationCurve};

  ${(p) => p.status === 'init' && { opacity: 0 }}
  ${(p) => p.status === 'enter' && { opacity: 1 }}
  ${(p) => p.status === 'exit' && { opacity: 0 }}
`;

const Fade: FunctionalComponent = ({ children }) => {
  const [status, setStatus] = useState<'init' | 'enter' | 'exit'>('init');
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    setStatus('enter');
  }, []);

  useEffect(() => {
    if (!isPresent) {
      setStatus('exit');
      setTimeout(() => safeToRemove?.(), FADE_DURATION);
    }
  }, [isPresent]);

  return <FadeRoot status={status}>{children}</FadeRoot>;
};

const StyledCardIcon = styled(CardIcon)`
  position: absolute;
`;

const Root = styled.div`
  position: relative;
  height: 100%;
  width: 24px;
`;

type Props = {
  type?: string | null;
};

const CreditCardNumberInputIcon: FunctionalComponent<Props> = ({ type }) => {
  const id = useMemo(() => Math.random(), [type]);
  const key = useMemo(() => `${type ?? 'default'}-${id}`, [type, id]);

  return (
    <Root>
      <AnimatePresence>
        {[
          <Fade key={key}>
            <StyledCardIcon cardType={type ?? 'default'} />
          </Fade>,
        ]}
      </AnimatePresence>
    </Root>
  );
};

export default CreditCardNumberInputIcon;
