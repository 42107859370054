import { FunctionalComponent } from 'preact';
import CountrySelect from '../CountrySelect/CountrySelect';

interface Props {
  type: string;
  hasErrors: boolean;
  inputId: string;
  onChange: (value: string | number) => void;
  disabled?: boolean;
}

const SelectManager: FunctionalComponent<Props> = ({
  type,
  onChange,
  ...otherProps
}) => {
  if (type === 'countrySelect') {
    return <CountrySelect onInputChange={onChange} {...otherProps} />;
  }

  return undefined;
};

export default SelectManager;
