import { FunctionalComponent } from 'preact';
import { Label } from '@primer-io/shared-library/components';

type Props = {
  labelContent?: string;
  className: string;
};

const BaseSurchargeContainer: FunctionalComponent<Props> = ({
  labelContent,
  className,
  children,
}) => {
  return (
    <div>
      <Label
        testId={'SurchargeItem.label'}
        text={labelContent}
        className={className}
      />
      <div style={{ marginTop: 4 }}>{children}</div>
    </div>
  );
};

export default BaseSurchargeContainer;
