import { createContext, FunctionalComponent } from 'preact';
import { useContext } from 'preact/hooks';

type Context = { disabled?: boolean };

const FormRendererContext = createContext<Context>({ disabled: false });

export const FormRendererProvider: FunctionalComponent<{ value: Context }> = ({
  value,
  children,
}) => <FormRendererContext.Provider value={value} children={children} />;

export const useFormRendererContext = () => useContext(FormRendererContext);
