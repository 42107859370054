/* eslint-disable */
// Modified by Aladin Taleb from : https://github.com/jahredhope/react-unistore

import { createContext } from 'preact';

import { useContext, useLayoutEffect, useReducer, useRef } from 'preact/hooks';
import { Action, Listener, Store } from 'unistore';
import { BaseStore, IBaseState } from './BaseStore';
import CheckoutStore, { ICheckoutState } from './CheckoutStore';

export function mapActions(
  actions: Action<any>[] | ((store: Store<any>) => Action<any>[]),
  store: Store<any>,
) {
  if (typeof actions === 'function') {
    actions = actions(store);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapped: Record<any, any> = {};
  for (const i in actions) {
    mapped[i] = store.action(actions[i]);
  }
  return mapped;
}

export function select(properties: string | string[]) {
  if (typeof properties === 'string') {
    properties = properties.split(/\s*,\s*/);
  }
  return (state: any) => {
    const selected: Record<any, any> = {};
    for (let i = 0; i < properties.length; i++) {
      selected[properties[i]] = state[properties[i]];
    }
    return selected;
  };
}

const UnistoreContext = createContext<CheckoutStore | null>(null);

export const { Provider } = UnistoreContext;

export const useStore = (): CheckoutStore => {
  const store = useContext(UnistoreContext);
  if (!store) {
    throw new Error("Missing context. Ensure you've rendered a Provider.");
  }
  return store;
};

export type UseAction = <State, Args extends any[]>(
  action: (
    state: State,
    ...args: Args
  ) => Promise<Partial<State>> | Partial<State> | void,
) => (...args: Args) => void;

/**
 * Used to add state definition to useAction
 * e.g.
 * export const useAction: TypedUseAction<State> = _useAction;
 */
export type TypedUseAction<State> = <Args extends any[]>(
  action: (
    state: State,
    ...args: Args
  ) => Promise<Partial<State>> | Partial<State> | void,
) => (...args: Args) => void;

export type UseSelector<State, Selected> = (
  selector: (state: State) => Selected,
) => Selected;

/**
 * Used to add state definition to useSelector
 * e.g.
 * export const useSelector: TypedUseSelector<State> = _useSelector;
 */
export type TypedUseSelector<State> = <Selected>(
  selector: (state: State) => Selected,
) => Selected;

type EqualityFn = (a: any, b: any) => any;

export const useStoreSelector = <
  _State extends IBaseState,
  Store extends BaseStore<_State>,
  State extends Store['stateType'], // Typescript magic to auto complete the actual state from the store
  Selected = any
>(
  store: Store | undefined,
  selector: (state: State) => Selected,
  defaultValue?: Selected,
  equalityFn?: EqualityFn,
): Selected => {
  // Allow store subscriptions to force render updates
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const resultRef = useRef<Selected | undefined>();

  resultRef.current = store
    ? selector(store.getState() as State)
    : defaultValue;

  useLayoutEffect(() => {
    const listener: Listener<any> = (state) => {
      const result = selector(state);
      if (
        equalityFn
          ? !equalityFn(resultRef.current, result)
          : resultRef.current !== result
      ) {
        forceUpdate({});
      }
    };
    const unsubscribe = store?.subscribe(listener);
    return () => {
      unsubscribe?.();
    };
  }, [store]);

  return resultRef.current as Selected;
};

export const useSelector = <Selected>(
  selector: (state: ICheckoutState) => Selected,
  defaultValue?: Selected,
  equalityFn?: EqualityFn,
): Selected => {
  const store = useStore();
  return useStoreSelector(
    store,
    selector,
    defaultValue,
    equalityFn,
  ) as Selected;
};
