const charset = '0123456789abcdef';

function sample(n: number): string {
  return Array(n)
    .fill(null)
    .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
    .join('');
}

export function uuid(): string {
  return [
    sample(8),
    '-',
    sample(4),
    '-',
    sample(4),
    '-',
    sample(4),
    '-',
    sample(12),
  ].join('');
}
