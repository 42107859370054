import { FunctionComponent } from 'preact';

type Props = {
  dim: boolean;
};

const Dim: FunctionComponent<Props> = ({ dim, children }) => {
  return (
    <div
      style={{
        transition: 'opacity 300ms linear',
        opacity: dim ? 0.3 : 1,
      }}
    >
      {children}
    </div>
  );
};

export default Dim;
