import { IBaseState, BaseStore } from '../../store/BaseStore';

interface SavePaymentMethodState extends IBaseState {
  isShowingUserDescription: boolean;

  userDescription: string;
}

const defaultState: SavePaymentMethodState = {
  isShowingUserDescription: false,

  userDescription: '',
};

export const SavePaymentMethodStoreSelector = {
  getIsShowingCardholderName: (s: SavePaymentMethodState) =>
    s.isShowingCardholderName,

  getIsShowingUserDescription: (s: SavePaymentMethodState) =>
    s.isShowingUserDescription,

  getUserDescription: (s: SavePaymentMethodState) => s.userDescription,
};

export class SavePaymentMethodStore extends BaseStore<SavePaymentMethodState> {
  static type = 'SAVE_PAYMENT_METHOD';

  get type() {
    return SavePaymentMethodStore.type;
  }

  showUserDescription(show: boolean) {
    this.produceState((draft) => {
      draft.isShowingUserDescription = show;
    });
  }

  setUserDescription(description: string) {
    this.produceState((draft) => {
      draft.userDescription = description;
    });
  }

  get userDescription(): string | null {
    return SavePaymentMethodStoreSelector.getUserDescription(this.getState());
  }

  get isShowingUserDescription() {
    return SavePaymentMethodStoreSelector.getIsShowingUserDescription(
      this.getState(),
    );
  }
}

const createSavePaymentMethodStore = () =>
  new SavePaymentMethodStore(defaultState);
export default createSavePaymentMethodStore;
