import { Translator } from '@eo-locale/core';
import { noop } from '../noop';

interface ITranslation {
  _(
    key: string,
    options?: { defaultMessage: string } & Record<string, unknown>,
  );
}

export class Translation implements ITranslation {
  private translator: Translator | undefined;

  private defaultTranslation?: TranslationUnit | undefined;

  constructor(translator?: Translator, defaultTranslation?: TranslationUnit) {
    if (translator) {
      this.translator = translator;

      //TODO: monitoring
      this.translator.onError = noop;
    }

    this.defaultTranslation = defaultTranslation;
  }

  _(
    key: string,
    options?: { defaultMessage?: string } & Record<string, unknown>,
  ): string {
    return (
      this.translator?.translate(key, {
        ...options,
        defaultMessage:
          options?.defaultMessage ??
          this.defaultTranslation?.translator?.translate(key, options),
      }) ?? key
    );
  }
}

export type TranslationUnit = Translation & Record<string, any>;

export const createTranslation = (
  locale: string,
  messages: Record<string, unknown> = {},
  defaultTranslation?: TranslationUnit,
): TranslationUnit => {
  // Dep Injection of translator (eo-locale)
  const translator = new Translator(locale, [{ language: locale, messages }]);
  const translation = new Translation(translator, defaultTranslation);

  // Create proxy to that "labels.someKey" is translated into "labels._(someKey)"
  // (backward compatible with the current implementation)
  const proxy = new Proxy(translation, {
    get: (obj, prop) => {
      if (!prop) {
        return '';
      }

      if (typeof prop !== 'string') {
        return '';
      }

      return prop in obj ? obj[prop] : obj._(String(prop));
    },
  });

  return proxy;
};
