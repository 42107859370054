import { FunctionalComponent } from 'preact';
import styled, { css } from 'styled-components';
import InputContainer from '../InputContainer/InputContainer';
import { getTextStyle } from '../utils';

type Props = {
  placeholder?: string | undefined;
  value?: string | number | undefined;
  inputId?: string;
  autoComplete?: string;
  name?: string;
  type?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  hasErrors?: boolean;
  onFocus?: (name?: string) => void;
  onBlur?: (name?: string) => void;
  onChange: (value) => void;
};

const getInputCss = (selector) => css`
  ${(p) => ({
    paddingLeft: selector(p)?.paddingHorizontal,
    paddingRight: selector(p)?.paddingHorizontal,
  })}

  ${(p) => getTextStyle(selector(p))}

  &:hover {
    ${(p) => getTextStyle(selector(p)?.hover)}
  }

  &:focus,
  &:focus-within {
    ${(p) => getTextStyle(selector(p)?.focus)}
  }

  &::placeholder {
    ${(p) => getTextStyle(selector(p)?.placeholder)}
  }

  &::selection {
    ${(p) => getTextStyle(selector(p)?.selection)}
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    ${(p) => getTextStyle(selector(p)?.webkitAutofill)}
  }
`;

const BaseInputCss = getInputCss((p) => p.theme.style?.input.base);
const ErrorInputCss = getInputCss((p) => p.theme.style?.input.error);

const StyledInput = styled.input`
  box-sizing: border-box;
  z-index: 1;
  padding: 0px;
  height: 100%;
  width: 100%;
  border: none !important;
  outline: none;
  background: transparent;

  ${BaseInputCss}
  ${(p) => p.hasErrors && ErrorInputCss}
`;

const Input: FunctionalComponent<Props> = ({
  placeholder,
  value,
  inputId,
  autoComplete,
  name,
  type,
  maxLength,
  minLength,
  disabled,
  hasErrors = false,
  onChange,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  // TODO: Add util funtion that determines the inputMode from the type
  // for better useability
  // Better handling of type. Type should help determine the inputMode
  return (
    <InputContainer hasErrors={hasErrors} {...otherProps}>
      <StyledInput
        id={inputId}
        className='PrimerCheckout__formInput'
        type={'text'}
        autocomplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        inputMode={type === 'number' ? 'numeric' : 'text'}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        hasErrors={hasErrors}
        onInput={(e: any) => onChange(e.target?.value)}
        onFocus={() => onFocus?.(name)}
        onBlur={() => onBlur?.(name)}
      />
    </InputContainer>
  );
};

export default Input;
