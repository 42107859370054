import { noop } from './noop';
import { ElementOrID } from '../types';
import { resolveElement } from './resolveElement';

interface EventListenerSubscription {
  (
    type: string,
    listener: EventListenerOrEventListenerObject,
    capture?: boolean,
  ): void;
}

export type CrossBrowserEventProducer =
  | {
      attachEvent: undefined;
      detachEvent: undefined;
      addEventListener: EventListenerSubscription;
      removeEventListener: EventListenerSubscription;
    }
  | {
      attachEvent: EventListenerSubscription;
      detachEvent: EventListenerSubscription;
      addEventListener: undefined;
      removeEventListener: undefined;
    };

export interface UnsubscribeCallback {
  (): void;
}

export function addEventListener(
  elementOrId: ElementOrID,
  eventName: string,
  listener: EventListenerOrEventListenerObject,
): UnsubscribeCallback {
  const node = <Nullable<CrossBrowserEventProducer>>resolveElement(elementOrId);

  if (!node) {
    return noop;
  }

  if (node.addEventListener) {
    node.addEventListener(eventName, listener, false);
    return () => node.removeEventListener(eventName, listener, false);
  }

  if (node.attachEvent) {
    node.attachEvent(`on${eventName}`, listener);
    return () => {
      if (node.detachEvent) {
        node.detachEvent(`on${eventName}`, listener);
      }
    };
  }

  return noop;
}
