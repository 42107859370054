import { Observable } from './Observable';
import {
  IFrameMessage,
  IFrameMessagePayload,
  IFrameInputMessagePayload,
} from './IFrameMessage';
import { uuid } from '../utils/uuid';

export class IFrameMessageBus extends Observable {
  private frames: Record<string, HTMLIFrameElement>;

  constructor() {
    super();
    this.frames = {};
  }

  onEvent(event: IFrameMessagePayload): void {
    if (event.meta.destination) {
      this.publish(event.meta.destination, event);
    }
  }

  add(iframe: HTMLIFrameElement): void {
    const name = iframe.getAttribute('data-primer-frame');

    if (name != null) {
      this.frames[name] = iframe;
    }
  }

  publish(name: string, event: IFrameInputMessagePayload): void {
    const iframe = this.frames[name];

    if (!iframe) {
      return;
    }

    const message = IFrameMessage.marshal(event);

    if (iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, '*');
    }
  }

  rpc(
    name: string,
    evt: IFrameInputMessagePayload,
  ): Promise<IFrameMessagePayload> {
    const channel = uuid();
    const event = { ...evt, meta: { ...evt.meta, replyTo: channel } };
    return new Promise((resolve) => {
      this.once(channel, resolve);
      this.publish(name, event);
    });
  }
}
