import { delay } from './delay';

/* eslint-disable no-underscore-dangle */
export default class CallQueue<Input = any, Output = any> {
  private calls: Array<{
    data: Input;
    promises: Array<{ resolve; reject }>;
    isCalling: boolean;
  }>;

  private _handleCall: ((data: Input) => Promise<Output>) | undefined;

  get handleCall(): ((data: Input) => Promise<Output>) | undefined {
    return this._handleCall;
  }

  set handleCall(value: ((data: Input) => Promise<Output>) | undefined) {
    this._handleCall = value;
    if (this._handleCall) {
      this.makeNextCallIfAllowed();
    }
  }

  public handleMerge: ((previousData: Input, data: Input) => Input) | undefined;

  public onCalling?: (isCalling: boolean) => void;

  private isScheduled: boolean;

  constructor() {
    this.calls = [];
    this.handleCall = undefined;
    this.handleMerge = undefined;
    this.onCalling = undefined;
    this.isScheduled = false;
  }

  public call<ResolvedType>(data: Input) {
    let resolve;
    let reject;

    const promise = new Promise<ResolvedType>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const promiseHandlers = { resolve, reject };

    if (this.handleMerge) {
      const nextCall = this.getNextCall();
      if (!nextCall) {
        this.calls.push({
          data,
          promises: [promiseHandlers],
          isCalling: false,
        });
      } else {
        const mergedData = this.handleMerge(nextCall.data, data);
        nextCall.data = mergedData;
        nextCall.promises.push(promiseHandlers);
      }
    } else {
      this.calls.push({
        data,
        promises: [promiseHandlers],
        isCalling: false,
      });
    }

    this.makeNextCallIfAllowed();

    return promise;
  }

  public get isCalling() {
    return this.isScheduled || !!this.calls.find(({ isCalling }) => isCalling);
  }

  private getNextCall() {
    return this.calls.find(({ isCalling }) => !isCalling);
  }

  private makeNextCallIfAllowed() {
    if (this.isScheduled) {
      return;
    }

    if (this.isCalling) {
      return;
    }

    if (this.calls.length === 0) {
      return;
    }

    if (!this.handleCall) {
      return;
    }

    this.makeNextCall();
  }

  private async makeNextCall() {
    const nextCall = this.getNextCall();
    if (!nextCall) {
      return;
    }

    this.isScheduled = true;
    await delay(1);

    nextCall.isCalling = true;
    this.onCalling?.(true);

    try {
      const result = await this.handleCall?.(nextCall.data);
      nextCall.promises.forEach(({ resolve }) => resolve(result));
    } catch (e) {
      nextCall.promises.forEach(({ reject }) => reject(e));
    }

    // Delete call
    this.calls.splice(0, 1);

    this.isScheduled = false;
    this.onCalling?.(false);

    // Make next call
    this.makeNextCallIfAllowed();
  }
}
