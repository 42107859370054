import styled from 'styled-components';

const SecondaryButton = styled.button`
  cursor: pointer;
  background: none;
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px 25px;
`;

export default SecondaryButton;
