import { FunctionalComponent } from 'preact';
import { useCheckoutStore } from '../../contexts';
import Select from '../Select/Select';

import { useCountryName } from './hooks';

interface Props {
  onInputChange: (value: string | number) => void;
  hasErrors: boolean;
  inputId: string;
  disabled?: boolean;
}

const CountrySelect: FunctionalComponent<Props> = ({
  onInputChange,
  hasErrors,
  disabled,
  inputId,
}) => {
  const { getCountries } = useCountryName();

  const store = useCheckoutStore();

  const labels = store.getTranslations();

  const placeholder = labels._('countrySelectPlaceholder');

  const countryData = getCountries().map(([countryCode, countryName]) => {
    return { name: countryName, value: countryCode };
  });

  return (
    <Select
      name={'countries'}
      id={inputId}
      placeholder={placeholder}
      data={countryData}
      onChange={onInputChange}
      hasErrors={hasErrors}
      disabled={disabled}
    ></Select>
  );
};

export default CountrySelect;
