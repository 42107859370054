import CheckoutStore from '../store/CheckoutStore';

export const createSetTokenizationEnabled = (store: CheckoutStore) => {
  return (isEnabled: boolean): void => {
    if (typeof isEnabled !== 'boolean') {
      throw TypeError('isEnabled should be a boolean');
    }

    store.setIsTokenizationEnabled(isEnabled);
  };
};
