import { ElementOrID } from '../types';
import { resolveElement } from '../utils/resolveElement';

export const getFirstFocusableChild = (
  elementOrId: ElementOrID,
): HTMLElement | undefined => {
  const element = resolveElement(elementOrId);
  const focusable:
    | NodeListOf<HTMLElement>
    | undefined = element?.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
  );

  return focusable?.[0];
};
