const ThreeDSScene = () => {
  return (
    <div
      id='primer-checkout-scene-3ds'
      className='PrimerCheckout__scene--3dsModal PrimerCheckout--hidden'
    >
      <div
        id='primer-checkout-3ds-modal'
        className='PrimerCheckout__modalBody'
      ></div>
    </div>
  );
};

export default ThreeDSScene;
