import { removeUndefinedFromObject } from '../../primer-sdk-web/src/utils/removeUndefined';
import {
  TextStyle,
  BlockStyle,
  CheckoutStyle,
  InternalStyle,
} from '../../primer-sdk-web/src/styles';

type StyledComponentProps = {
  theme: {
    style?: CheckoutStyle;
  };
};

/* 
  // Before - Very verbose, not DRY
   const Container = styled.div`
    ${props => getTextStyle(props.theme?.style?.submitButton)}
    ${props => getBlockStyle(props.theme?.style?.submitButton)}
  `


  // After: easier to read, better autocompletion, more DRY
  const withSubmitButtonStyle = createWithStyle(style => style.submitButton)
  
  const Container = styled.div`
    ${withSubmitButtonStyle(style => getTextStyle(style))}
  `
*/

/**
 *
 * @param selector Function that takes `checkoutStyle` and returns a style within this object
 * @returns A `withStyle` function
 */
export const createWithStyle = <Style>(
  selector: (
    checkoutStyle?: CheckoutStyle & InternalStyle,
  ) => Style | undefined,
) => (fn: (style?: Style) => any) => (p: StyledComponentProps) =>
  fn(selector(p.theme?.style));

export const getTextStyle = (style?: TextStyle) => {
  return {
    color: style?.color,
    fontFamily: style?.fontFamily,
    fontWeight: style?.fontWeight,
    fontSize: style?.fontSize,
    fontSmoothing: style?.fontSmoothing,
    lineHeight: style?.lineHeight,
    textTransform: style?.textTransform,
    letterSpacing: style?.letterSpacing,
  };
};

export const getBlockStyle = (style?: BlockStyle) => {
  return removeUndefinedFromObject({
    background: style?.background,
    borderStyle: style?.borderStyle,
    borderWidth: style?.borderWidth,
    borderColor: style?.borderColor,
    borderRadius: style?.borderRadius,
    boxShadow: style?.boxShadow,
  });
};
