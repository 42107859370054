import { ICheckoutState } from '../../primer-sdk-web/src/store/CheckoutStore';
import {
  Provider,
  TypedUseSelector,
  useSelector as _useSelector,
  useStoreSelector as _useStoreSelector,
  useStore,
} from '../../primer-sdk-web/src/store/PreactUnistore';

export const useCheckoutStore = useStore;

export const useSelector: TypedUseSelector<ICheckoutState> = _useSelector;

export const useStoreSelector = _useStoreSelector;

export { Provider };
