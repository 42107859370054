import { useCallback, useEffect, useRef } from 'preact/hooks';
import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import produce from 'immer';
import { createFormInputs } from '../utils/createFormInputs';
import {
  IFormSchema,
  IFormUpdateOptions,
  IInput,
  UseFormOptions,
} from '../types';
import { getFieldErrors } from '../utils/validation/fieldValidation';
import DEFAULT_VALIDATORS from '../utils/validation/validators';
import { useRefState } from '../../utils/hooks';
import mapInputErrorMessages from '../utils/errors/mapInputErrorMessages';

const checkFormValidity = (fields: Record<string, IInput>) =>
  !Object.values(fields).find(
    (input: IInput) => input.errorMessages && input.errorMessages.length > 0,
  );

const useFormState = (formSchema: IFormSchema, options?: UseFormOptions) => {
  const store = useCheckoutStore();
  const labels = store.getTranslations();

  ///////////////////////////////////////////
  // State
  ///////////////////////////////////////////
  const [getFormInputs, setFormInputs] = useRefState(
    createFormInputs(formSchema),
  );

  // Inputs that are not in the schema yet
  const temporaryFormInputs = useRef<Record<string, { value: string }>>({})
    .current;

  // Form state
  const [isSubmitted, setSubmitted] = useRefState(false);

  ///////////////////////////////////////////
  // Side Effect
  ///////////////////////////////////////////
  // Updates state when input is added or removed from schema
  useEffect(() => {
    const newFormInputs = createFormInputs(formSchema);

    const updatedFormInputs = produce(getFormInputs(), (draft) => {
      // Delete input removed from schema
      Object.values(draft).forEach((input: IInput) => {
        if (input && !newFormInputs[input?.name]) {
          delete draft[input.name];
        }
      });

      // If input exists, do nothing, else add to state (may take info from temporaryFormInputs)
      Object.values(newFormInputs).forEach((input) => {
        if (draft[input.name]) {
          return;
        }
        draft[input.name] = input;

        if (!draft[input.name].value && temporaryFormInputs[input.name].value) {
          draft[input.name].value = temporaryFormInputs[input.name].value;
          delete temporaryFormInputs[input.name];
        }
      });
    });

    setFormInputs(updatedFormInputs);
  }, [formSchema]);

  ///////////////////////////////////////////
  // Utils
  ///////////////////////////////////////////
  const getErrorMessages = useCallback(
    (input: IInput) => {
      const { value, validation, errorMessageKey } = input;

      const fieldErrors = getFieldErrors(value, validation.validationRules, {
        ...DEFAULT_VALIDATORS,
        ...options?.additionalValidators,
      });

      return mapInputErrorMessages(fieldErrors, errorMessageKey, labels);
    },
    [options?.additionalValidators, labels],
  );

  const validateForm = useCallback(() => {
    const updatedInputFields = produce(getFormInputs(), (draft) => {
      Object.values(draft).forEach((input: IInput) => {
        draft[input.name].errorMessages = getErrorMessages(input);
        draft[input.name].errorVisible = true;
      });
    });

    setFormInputs(updatedInputFields);

    return checkFormValidity(updatedInputFields);
  }, [getErrorMessages]);

  ///////////////////////////////////////////
  // Callbacks
  ///////////////////////////////////////////

  const handleInputChange = useCallback((name: string, value: any) => {
    setFormInputs(
      produce(getFormInputs(), (draft) => {
        draft[name].value = value;
        draft[name].errorMessages = getErrorMessages(draft[name]);
        draft[name].dirty = true;
      }),
    );
  }, []);

  // Sets state on input focus
  const handleInputFocus = useCallback((name: string) => {
    setFormInputs(
      produce(getFormInputs(), (draft) => {
        draft[name].focused = true;
      }),
    );
  }, []);

  // Sets state on input blur
  const handleInputBlur = useCallback((name: string) => {
    setFormInputs(
      produce(getFormInputs(), (draft) => {
        if (
          name &&
          draft[name].validation.validationEvent === 'BLUR' &&
          !isSubmitted
        ) {
          const errorMessages = getErrorMessages(draft[name]);
          draft[name].errorMessages = errorMessages;
          draft[name].errorVisible = errorMessages.length > 0;
        }
        draft[name].focused = false;
      }),
    );
  }, []);

  ///////////////////////////////////////////
  // External functions
  ///////////////////////////////////////////

  // Submits form and performs validation
  const submitForm = useCallback(() => {
    setSubmitted(true);
    const isValid = validateForm();
    return { isValid, data: getFormInputs() };
  }, []);

  // Set input value
  const setFormData = (formUpdateData: Record<string, IFormUpdateOptions>) => {
    const updatedFormInputs = produce(getFormInputs(), (draft) => {
      Object.entries(formUpdateData).forEach(([name, { value }]) => {
        if (draft[name]) {
          draft[name].value = value;
        } else {
          temporaryFormInputs[name] = { value };
        }
      });
    });

    setFormInputs(updatedFormInputs);
  };

  // Reset form
  const resetForm = () => {
    setSubmitted(false);
    resetFormValidation();
    resetFormData();
  };

  const resetFormValidation = () => {
    setSubmitted(false);

    setFormInputs(
      produce(getFormInputs(), (draft) => {
        Object.values(draft).forEach((input: IInput) => {
          input.errorVisible = false;
          input.errorMessages = [];
        });
      }),
    );
  };

  const resetFormData = () => {
    setSubmitted(false);

    setFormInputs(
      produce(getFormInputs(), (draft) => {
        Object.values(draft).forEach((input: IInput) => {
          input.value = '';
        });
      }),
    );
  };

  return {
    onInputChange: handleInputChange,
    onInputFocus: handleInputFocus,
    onInputBlur: handleInputBlur,
    submitForm,
    setFormData,
    resetForm,
    resetFormValidation,
    resetFormData,
    formData: getFormInputs(),
  };
};

export default useFormState;
