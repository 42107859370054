import { useCheckoutModule } from '@primer-io/shared-library/contexts';
import { IFormSchema, useForm } from '@primer-io/shared-library/form';
import { SavePaymentMethodStore } from '.';

export const cardDescriptionForm: IFormSchema = {
  fields: [
    {
      labelKey: 'cardDescription',
      inputGroups: [
        {
          id: 'cardDescription',
          orientation: 'vertical',
          inputs: [
            {
              id: 'cardDescription',
              type: 'input',
              name: 'cardDescription',
              placeholderKey: 'cardDescriptionPlaceholder',
              errorMessageKey: 'cardDescriptionError',
              validation: {
                validationRules: [],
              },
            },
          ],
        },
      ],
    },
  ],
};

export const useCardDescriptionForm = () => {
  const form = useForm(cardDescriptionForm);
  const savePaymentMethod = useCheckoutModule<SavePaymentMethodStore>(
    'SAVE_PAYMENT_METHOD',
  );

  if (!savePaymentMethod) {
    return undefined;
  }

  if (!savePaymentMethod.isShowingUserDescription) {
    return undefined;
  }

  return { ...form };
};
