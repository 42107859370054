import { IFormSchema, IInput, IInputGroup } from '../types';

export function createFormInputs(
  formInputSchema: IFormSchema,
): Record<string, IInput> {
  const inputs = formInputSchema?.fields?.flatMap((field) =>
    field?.inputGroups.flatMap((inputGroup: IInputGroup) => inputGroup.inputs),
  );

  const formInputs: Record<string, IInput> = inputs.reduce((acc, input) => {
    acc[input.name] = {
      id: input.id ?? '',
      name: input.name,
      autocomplete: input.autocomplete ?? '',
      value: input.value ?? '',
      type: input.type,
      placeholderKey: input.placeholderKey,
      errorMessageKey: input.errorMessageKey,
      errorMessages: [],
      errorVisible: false,
      focused: false,
      dirty: false,
      maxLength: input.maxLength,
      minLength: input.minLength,
      validation: {
        validationEvent: input.validation?.validationEvent ?? 'SUBMIT',
        validationRules: input.validation?.validationRules,
      },
    };

    return acc;
  }, {});

  return formInputs;
}
