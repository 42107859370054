import {
  animationCurve,
  BaseButton,
} from '@primer-io/shared-library/components';
import { getTextStyle } from '@primer-io/shared-library/components/utils';
import { useCheckoutContext } from '@primer-io/shared-library/contexts';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { TextDividerStyle } from './TextDivider';

const Root = styled(BaseButton)`
  ${TextDividerStyle}

  text-align: center;

  transition: all ${animationCurve} 300ms;
  & > * {
    transition: all ${animationCurve} 300ms;
  }

  &:not(:disabled):hover {
    & > * {
      transform: scale(1.02);
    }
  }

  & .PrimerCheckout__label {
    ${(p) => getTextStyle(p.theme.style?.showMorePaymentMethodsButton?.base)}
  }

  & .PrimerCheckout__label--primary {
    ${(p) => ({
      color: p.theme.style?.showMorePaymentMethodsButton?.base?.color,
    })}
  }

  &:disabled .PrimerCheckout__label {
    ${(p) =>
      getTextStyle(p.theme.style?.showMorePaymentMethodsButton?.disabled)}
  }
`;

type Props = {
  disabled: boolean;
  onClick: () => void;
};

const ShowPaymentMethodsButton: FunctionalComponent<Props> = ({
  disabled,
  children,
  onClick,
  ...otherProps
}) => {
  const { className } = useCheckoutContext();

  return (
    <Root
      type='button'
      id='primer-checkout-navigate-to-payment-methods'
      className='PrimerCheckout__sceneElement PrimerCheckout__textButton'
      disabled={disabled}
      onClick={onClick}
      {...otherProps}
    >
      <span
        className={className('label', {
          primary: !disabled,
          muted: disabled,
        })}
      >
        {children}
      </span>
    </Root>
  );
};

export default ShowPaymentMethodsButton;
