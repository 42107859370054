import { IModule, IModuleLoader, ModuleKey, ModuleStatus } from './types';

interface ModuleOptions {
  key: ModuleKey;
  moduleLoader: IModuleLoader;
}

export default class Module implements IModule {
  readonly key: ModuleKey;

  readonly status: ModuleStatus;

  private moduleLoader: IModuleLoader;

  constructor({ key, moduleLoader }: ModuleOptions) {
    this.key = key;
    this.moduleLoader = moduleLoader;
  }

  public async import(exportName = 'default'): Promise<unknown> {
    const moduleDescriptor = await this.moduleLoader.loadModule(this.key);
    if (!moduleDescriptor.module) {
      throw new Error('Module cannot be loaded');
    }
    return moduleDescriptor.module[exportName];
  }
}
