import { resolveElement } from './resolveElement';
import { ElementOrID } from '../types';

export function getClassNames(elementOrId: ElementOrID): string[] {
  const element = resolveElement(elementOrId);

  if (element == null) {
    return [];
  }

  return element.className.trim().split(/\s+/g).filter(Boolean);
}
