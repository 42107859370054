import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { useLanguageCode } from '../../contexts';

import {
  loadLanguage,
  getCountryName as _getCountryName,
  getCountries as _getCountries,
} from '../../../primer-sdk-web/src/utils/i18n/CountryTranslation';

export const useBaseCountryName = (languageCode: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentLanguageCodeRef = useRef('en');

  const getCountryName = useCallback(
    (countryCode: string) =>
      _getCountryName(countryCode, currentLanguageCodeRef.current),
    [],
  );

  const getCountries = useCallback(
    () => _getCountries(currentLanguageCodeRef.current),
    [],
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const loaded = await loadLanguage(languageCode);
      if (loaded) {
        currentLanguageCodeRef.current = languageCode;
      }

      setIsLoading(false);
    })();
  }, [languageCode]);

  return {
    loading: isLoading,
    getCountryName,
    getCountries,
  };
};

export const useCountryName = () => {
  const languageCode = useLanguageCode();
  return useBaseCountryName(languageCode);
};
