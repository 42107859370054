import { DeprecationWarnings, Warnings } from '../assets/warnings/Warnings';
import { interpolate } from './interpolate';
import window from './window';

export enum DeprecationWarningType {
  OPTION_SETTER = 'optionSetter',
  TOKENIZE = 'tokenize',
  VALIDATE = 'validate',
  SET_PURCHASE_INFO = 'setPurchaseInfo',
}

export enum WarningType {
  TOKENIZE = 'tokenize',
  MISSING_ORDER_DETAILS_UPDATE = 'orderMissingInClientSession',
  MISSING_ORDER_DETAILS_INIT = 'orderNeverProvided',
  MISSING_AMOUNT_IN_CLIENT_SESSION = 'noTotalAmountInClientSession',
  MISSING_CURRENCY_IN_CLIENT_SESSION = 'noCurrencyInClientSession',
  OPTIONS_AND_CLIENT_SESSION_PROVIDED = 'bothOptionsAndClientSession',
  NO_COUNTRY_CODE = 'countryCodeNotProvided',
  NO_CURRENCY_CODE = 'currencyCodeNotProvided',
  PURCHASE_AND_ORDER_DETAILS_PROVIDED = 'bothPurchaseInfoAndOrderDetails',
  SET_CLIENT_TOKEN_WITHOUT_TOKEN = 'setClientTokenWithoutToken',
  APPLE_PAY_VERSION_NOT_SUPPORTED = 'applePayVersionNotSupported',
}

export function logWarning(
  warningType: string,
  messageVariables?: Record<string, string>,
) {
  const warning = DeprecationWarnings[warningType] ?? Warnings[warningType];
  if (messageVariables) {
    warn(interpolate(warning, messageVariables));
    return;
  }
  warn(warning);
}

function warn(warning: string) {
  if (warning) {
    window.console.warn(warning);
  }
}
