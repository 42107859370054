import { ElementOrID } from '../types';
import { resolveElement } from './resolveElement';
import { getClassNames } from './getClassNames';

export function toggleClass(
  elementOrId: ElementOrID,
  className: string,
  enabled?: boolean,
): void {
  const element = resolveElement(elementOrId);

  if (element == null) {
    return;
  }

  const tokens = getClassNames(element);
  const hasClass = tokens.includes(className);

  if (enabled === hasClass) {
    return;
  }

  const add = typeof enabled === 'boolean' ? enabled : !hasClass;

  if (add) {
    element.className = [...tokens, className].join(' ');
  } else {
    element.className = tokens.filter((name) => name !== className).join(' ');
  }
}
