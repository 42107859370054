import { CardMetadata } from '../hosted-scripts/CardMetadata';
import { ClientSessionActionService } from './client-session/ClientSessionActionService';

interface CardMetaChangeHandler {
  (meta: CardMetadata): void;
}

export function cardTypeChangeHandler(
  clientSessionActionService?: ClientSessionActionService,
): CardMetaChangeHandler {
  return (meta) => {
    clientSessionActionService?.selectCardNetwork(meta.type);
  };
}
