import { paymentInstrumentTypeToPaymentMethodType } from '../../enums/Tokens';
import { PaymentMethodToken } from '../../types';
import { IPaymentMethodContextFactory } from '../../core/PaymentMethodContextFactory';
import { ErrorCode, PrimerClientError } from '../../errors';
import { VaultedPaymentMethod } from './Vault';

export default class VaultPaymentTokenizationService {
  private paymentMethodContextFactory: IPaymentMethodContextFactory;

  constructor(paymentMethodContext: IPaymentMethodContextFactory) {
    this.paymentMethodContextFactory = paymentMethodContext;
  }

  async tokenize(vaultedPaymentMethod: VaultedPaymentMethod) {
    // Get the paymentMethodType from vaultedPaymentMethod
    // This is required to create a paymentMethodContext for this payment method
    const { paymentInstrumentType } = vaultedPaymentMethod;
    const paymentMethodType = paymentInstrumentTypeToPaymentMethodType(
      paymentInstrumentType,
    );

    if (!paymentMethodType) {
      console.error(
        `Payment Instrument Type ${paymentInstrumentType} is not recognized or not supported.`,
      );
      return;
    }

    const paymentMethodContext = this.paymentMethodContextFactory.createPaymentMethodContext(
      { paymentMethodType },
    );

    if (!paymentMethodContext.store.getIsTokenizationEnabled()) {
      paymentMethodContext.progress.didNotStart('TOKENIZATION_DISABLED');
      return;
    }

    const shouldStart = await paymentMethodContext.progress.shouldStart();

    if (!shouldStart) {
      paymentMethodContext.progress.didNotStart(
        'TOKENIZATION_SHOULD_NOT_START',
      );
      return;
    }

    paymentMethodContext.progress.start();

    const {
      error,
      data: paymentMethodToken,
    } = await paymentMethodContext.api.post<unknown, PaymentMethodToken>(
      `/payment-instruments/${vaultedPaymentMethod.id}/exchange`,
      {},
      { apiVersion: '2021-12-10' },
    );

    if (error || !paymentMethodToken) {
      paymentMethodContext.progress.error(
        PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
          message: 'Cannot create a payment method token with a vaulted token',
        }),
      );

      return;
    }

    paymentMethodContext.progress.success(paymentMethodToken);
  }
}
