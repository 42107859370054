import { BrowserClient, BrowserOptions } from '@sentry/browser';

// Only need one BrowserClient needed for the whole library
let clientPromise: Promise<BrowserClient> | null = null;

const loadSentry = async () => {
  const {
    BrowserClient: BrowserClientConstructor,
    defaultIntegrations,
    makeMain,
  } = await import('@sentry/browser');

  return {
    BrowserClientConstructor,
    defaultIntegrations,
    makeMain,
  };
};

const initSentry = (
  {
    BrowserClientConstructor,
    defaultIntegrations,
  }: {
    BrowserClientConstructor: typeof BrowserClient;
    defaultIntegrations: any;
  },
  options: BrowserOptions = {},
) => {
  const client = new BrowserClientConstructor({
    integrations: [...defaultIntegrations],

    ...options,
  });

  return client;
};

export const getSentryClient = (
  options: BrowserOptions,
): Promise<BrowserClient> => {
  if (clientPromise) {
    return clientPromise;
  }

  clientPromise = loadSentry().then((module) => initSentry(module, options));

  return clientPromise;
};
