import { APIErrorShape } from '../../core/Api';
import { ErrorCode, PrimerClientError } from '../../errors';

export function errorFromAPIResponse(err: APIErrorShape | null) {
  if (!err) {
    return PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
      message: 'Failed to tokenize card',
    });
  }

  switch (err.errorId) {
    case 'InvalidCardNumber':
      return PrimerClientError.fromErrorCode(ErrorCode.CARD_NUMBER_ERROR, err);
    default:
      return PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, err);
  }
}
