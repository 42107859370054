import { firstElementChild } from './Node.firstElementChild';

export function prepend(parent: Node, element: Node): void {
  const firstChild = firstElementChild(parent);

  if (firstChild == null) {
    parent.appendChild(element);
    return;
  }

  parent.insertBefore(element, firstChild);
}
