import en from 'i18n-iso-countries/langs/en.json';

type LanguageData = typeof en;

export const registeredLanguages: Record<string, LanguageData> = {};

const registerLanguage = (languageData: LanguageData) => {
  registeredLanguages[languageData.locale] = languageData;
};

export const resetLanguages = () => {
  Object.getOwnPropertyNames(registeredLanguages).forEach((prop) => {
    delete registeredLanguages[prop];
  });

  registerLanguage(en);
};
resetLanguages();

const countryCodes = Object.keys(
  registeredLanguages.en.countries,
).sort((a, b) => a.localeCompare(b));

export const getCountryName = (
  countryCode: string,
  languageCode: string,
): string => {
  const countryName = registeredLanguages[languageCode]?.countries[countryCode];
  if (!countryName) {
    return '';
  }

  if (Array.isArray(countryName)) {
    // The second country name is shorter
    return countryName[1];
  }

  return countryName;
};

export const getCountryCodes = () => countryCodes;

export const getCountries = (languageCode: string) =>
  getCountryCodes()
    .map((countryCode) => [
      countryCode,
      getCountryName(countryCode, languageCode),
    ])
    .sort(([, countryName1], [, countryName2]) =>
      countryName1.localeCompare(countryName2, languageCode),
    );

export const loadLanguage = async (languageCode: string) => {
  try {
    const languageData = await import(
      `i18n-iso-countries/langs/${languageCode}.json`
    );
    registerLanguage(languageData);
  } catch (e) {
    return false;
  }

  return true;
};

registerLanguage(en);
