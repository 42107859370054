import { Api } from './Api';

interface LongPollServices {
  api: Api;
}

interface LongPollOptions<T> {
  url: string;
  timeout: number;
  pollInterval: number;
  predicate(response: T): boolean;
}

interface LongPollOptionsInternal<T> extends LongPollOptions<T> {
  startTime: number;
}

export class LongPoll {
  private api: Api;

  private isStarted: boolean;

  constructor(services: LongPollServices) {
    this.api = services.api;
    this.isStarted = false;
  }

  start<T>(options: LongPollOptions<T>): Promise<T | null> {
    this.isStarted = true;
    return this.request<T>({ ...options, startTime: Date.now() });
  }

  stop() {
    this.isStarted = false;
  }

  private async request<T>(
    options: LongPollOptionsInternal<T>,
  ): Promise<T | null> {
    if (!this.isStarted) {
      this.stop();
      return null;
    }

    const dt = Date.now() - options.startTime;

    if (dt > options.timeout) {
      this.stop();
      return null;
    }

    const response = await this.api.get<T>(options.url);

    if (response.data && options.predicate(response.data)) {
      this.stop();
      return response.data;
    }

    /**
     * Notice that errors are ignored here, this is intentional
     */

    return this.tick(options);
  }

  private async tick<T>(
    options: LongPollOptionsInternal<T>,
  ): Promise<T | null> {
    await new Promise((resolve) => setTimeout(resolve, options.pollInterval));
    return this.request(options);
  }
}
