import { useRef, useState, useCallback } from 'preact/hooks';

export function useRefState<T>(defaultState?: T) {
  const ref = useRef<T>(defaultState);

  // Only use for rerendering
  const [, setInternalState] = useState(defaultState);

  const getState = () => ref.current;

  const setState = useCallback(
    (updatedState: T) => {
      ref.current = updatedState;
      setInternalState(updatedState);
    },
    [setInternalState],
  );

  return [getState, setState] as [() => T, (updatedState: T) => void];
}
