import { ElementOrID } from '../types';

export function resolveElement(
  elementOrId: ElementOrID,
): Nullable<HTMLElement> {
  if (!elementOrId) {
    return null;
  }

  return typeof elementOrId === 'string'
    ? window.document.getElementById(elementOrId as string)
    : elementOrId;
}
