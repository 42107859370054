import { ISuccessSceneHandler } from '../core/SuccessSceneHandler';
import { SceneEnum } from '../enums/Checkout';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  SuccessScreenOptions,
  SuccessScreenType,
} from '../types';
import { IViewUtils } from './ui/types';

export const SuccessScenes = {
  [SuccessScreenType.PAYMENT_METHOD]: SceneEnum.SUCCESS_PAYMENT_METHOD,
  [SuccessScreenType.CHECK]: SceneEnum.SUCCESS_CHECK,
};

export const showSuccessScene = ({
  viewUtils,
  options,
}: {
  viewUtils: IViewUtils;
  options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions;
}) => {
  if (options.successScreen) {
    const successScene = SuccessScenes[options.successScreen.type];
    if (successScene) {
      viewUtils.store.setScene(successScene);
      return true;
    }
  }

  return false;
};

export const createOptionsSuccessSceneHandler = (options?: {
  successScreen?: SuccessScreenOptions;
}): ISuccessSceneHandler => ({
  getSuccessScene: () => {
    if (options?.successScreen === false) {
      return false;
    }

    if (options?.successScreen) {
      return SuccessScenes[options.successScreen.type];
    }

    return undefined;
  },
  supportsSuccessSceneType: () => true,
});

export const getSuccessScene = (
  options?: { successScreen?: SuccessScreenOptions },
  successSceneHandler?: ISuccessSceneHandler,
): string | null => {
  const optionsSuccessSceneHandler = createOptionsSuccessSceneHandler(options);

  let successScene: string | false | undefined;
  // Default success scene to what's specified in options.successScene.type
  successScene = optionsSuccessSceneHandler.getSuccessScene();

  // If the success screne is false, that means the develooper forced it to be false
  // No success scene should be displayed
  if (successScene === false) {
    return null;
  }

  if (successScene) {
    // Check if the success scene supported by the current payment method
    if (!successSceneHandler) {
      return successScene;
    }

    if (successSceneHandler?.supportsSuccessSceneType(successScene)) {
      return successScene;
    }
  }

  //Get the success scene from the payment method handler
  successScene = successSceneHandler?.getSuccessScene();

  if (!successScene) {
    return SuccessScenes.CHECK;
  }

  return successScene;
};
