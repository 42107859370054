import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { Dash } from '../keyframes';

const Root = styled.div`
  & .spinner .path {
    stroke: ${(p) => p.$color ?? p.theme.style?.loadingScreen.color};
  }

  display: grid;
  place-content: center;

  & .spinner {
    animation: rotate 1.2s linear infinite;
    z-index: 2;

    height: 32px;
    width: 32px;
    margin: 24px 0px;
  }

  & .path {
    stroke-linecap: round;
    animation: ${Dash} 1.5s ease-in-out infinite;
  }
`;

type Props = {
  color?: string;
};

const Spinner: FunctionalComponent<Props> = ({ color }) => {
  return (
    <Root className='PrimerCheckout__spinner' $color={color}>
      <svg className='spinner' viewBox='0 0 50 50'>
        <circle
          className='path'
          cx='25'
          cy='25'
          r='20'
          fill='none'
          stroke-width='5'
        ></circle>
      </svg>
    </Root>
  );
};

export default Spinner;
