import { ProgressEvent as ProgressEventEnum } from '../enums/Tokenization';
import { ErrorCode, PrimerClientError } from '../errors';
import {
  OnTokenizeDidNotStart,
  OnTokenizeShouldStart,
  PaymentMethodToken,
  PaymentMethodType,
} from '../types';
import { APIErrorShape } from './Api';
import { DecodedClientToken } from './ClientTokenHandler';
import { ISuccessSceneHandler } from './SuccessSceneHandler';

type ValueOf<T> = T[keyof T];

interface IProgressEvent<T extends ValueOf<ProgressEventEnum>> {
  type: T;
}

type ProgressEvent =
  | IProgressEvent<'tokenize-started'>
  | IProgressEvent<'tokenize-did-not-start'>
  | (IProgressEvent<'tokenize-success'> & { data: PaymentMethodToken })
  | (IProgressEvent<'tokenize-error'> & { data: PrimerClientError });

export type SuccessClientTokenHandlerOptions = {
  decodedClientToken: DecodedClientToken;
};

export type SuccessCallbackOptions = {
  successSceneHandler?: ISuccessSceneHandler;
  clientTokenHandler?: {
    clientTokenIntent: string;
    handler: (options: SuccessClientTokenHandlerOptions) => Promise<any>;
    onError?: () => void;
  };
};
export interface ProgressNotifierCallbacks {
  onTokenizeShouldStart: OnTokenizeShouldStart | undefined;
  onTokenizeDidNotStart: OnTokenizeDidNotStart;
  onTokenizeProgress(event: ProgressEvent): void;
  onTokenizeStart(): void;
  onTokenizeEnd(): void;
  onTokenizeError(error: PrimerClientError): void;
  onTokenizeSuccess(
    token: PaymentMethodToken,
    options?: SuccessCallbackOptions,
  ): void;
}

export class ProgressNotifier {
  private callbacks: ProgressNotifierCallbacks;

  private paymentMethodType: PaymentMethodType;

  constructor(
    callbacks: ProgressNotifierCallbacks,
    paymentMethodType: PaymentMethodType,
  ) {
    this.callbacks = callbacks;
    this.paymentMethodType = paymentMethodType;
  }

  ///////////////////////////////////////////
  // Progress
  ///////////////////////////////////////////

  shouldStart(data?: {
    paymentMethodType?: PaymentMethodType;
  }): boolean | Promise<boolean> {
    if (!this.paymentMethodType) {
      return true;
    }

    if (!this.callbacks.onTokenizeShouldStart) {
      return true;
    }

    return this.callbacks.onTokenizeShouldStart?.(
      data ?? {
        paymentMethodType: this.paymentMethodType,
      },
    );
  }

  start(): void {
    this.callbacks.onTokenizeProgress({ type: 'tokenize-started' });
    this.callbacks.onTokenizeStart();
  }

  didNotStart(
    reason: 'TOKENIZATION_DISABLED' | 'TOKENIZATION_SHOULD_NOT_START',
  ): void {
    this.callbacks.onTokenizeProgress({ type: 'tokenize-did-not-start' });
    this.callbacks.onTokenizeEnd();
    this.callbacks.onTokenizeDidNotStart(reason);
  }

  success(token: PaymentMethodToken, options?: SuccessCallbackOptions) {
    this.callbacks.onTokenizeProgress({
      type: 'tokenize-success',
      data: token,
    });
    this.callbacks.onTokenizeEnd();
    this.callbacks.onTokenizeSuccess(token, options);
  }

  error(error: string | APIErrorShape | PrimerClientError) {
    const err = formatError(error);
    this.callbacks.onTokenizeProgress({ type: 'tokenize-error', data: err });
    this.callbacks.onTokenizeEnd();
    this.callbacks.onTokenizeError(err);
  }
}

function formatError(
  error: string | APIErrorShape | PrimerClientError,
): PrimerClientError {
  if (error instanceof PrimerClientError) {
    return error;
  }

  return PrimerClientError.fromErrorCode(
    ErrorCode.TOKENIZATION_ERROR,
    typeof error === 'string' ? { message: error } : error,
  );
}
