export enum PaymentFlow {
  DEFAULT = 'DEFAULT',
  PREFER_VAULT = 'PREFER_VAULT',
}

export enum ProgressEvent {
  TOKENIZE_STARTED = 'tokenize-started',
  TOKENIZE_ERROR = 'tokenize-error',
  TOKENIZE_SUCCESS = 'tokenize-success',
  TOKENIZE_DID_NOT_START = 'tokenize-did-not-start',
}

export enum ThreeDSecureStatus {
  SUCCESS = 'AUTH_SUCCESS',
  FAILED = 'AUTH_FAILED',
  SKIPPED = 'SKIPPED',
  CHALLENGE = 'CHALLENGE',
}
