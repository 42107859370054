import { FunctionalComponent } from 'preact';
import styled from 'styled-components';

const StyledBaseButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;

  font-family: inherit;
  outline-offset: 2px;

  &:disabled {
    cursor: auto;
  }
`;

const BaseButton: FunctionalComponent = (props) => (
  <StyledBaseButton role='button' type='button' {...props} />
);

export default BaseButton;
