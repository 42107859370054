import { FunctionalComponent } from 'preact';
import { useMemo, useState, useLayoutEffect, useRef } from 'preact/hooks';
import styled, { css } from 'styled-components';
import { animationCurve } from '@primer-io/shared-library/components';

import { snakeCaseToLowerCase } from '../utils/snakeCaseToLowerCase';
import { CardNetworkOption } from '../models/ClientSession';
import formatAmount from '../utils/formatAmount';
import { Alpha3CurrencyCode, SupportedLocale } from '../types';

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  transition: opacity ${animationCurve} 300ms, transform ${animationCurve} 300ms;
  opacity: 1;
  height: 100%;

  ${(p) =>
    p.hidden &&
    css`
      transition: all ${animationCurve} 300ms;
      transform: translateX(100%);
      opacity: 0;
    `}
`;

const SurchargeText = styled.span`
  display: inline-flex;
  align-items: center;

  padding: 0px 5px;
  background: #f8f8f8;
  border-radius: 4px;

  font-size: 15px;
  font-weight: 500;
  opacity: 1;
`;

export interface Props {
  networkType: string | null;
  cardNumberLength: number;
  cardNetworkOptions?: CardNetworkOption[];
  currencyCode?: Alpha3CurrencyCode;
  locale: SupportedLocale;
}

const MIN_CARD_LENGTH_CHECK_TYPE = 2;

const SurchargeCard: FunctionalComponent<Props> = ({
  networkType,
  cardNumberLength,
  cardNetworkOptions,
  currencyCode,
  locale,
}) => {
  const surchargeTextRef = useRef<HTMLDivElement>();

  const [localizedSurchargeAmount, setLocalizedSurchargeAmount] = useState('');
  const [surchargeTextWidth, setSurchargeTextWidth] = useState(0);

  const networkWithSurcharge = useMemo(
    () =>
      cardNetworkOptions?.find((option) => {
        return (
          snakeCaseToLowerCase(option.type) ===
            snakeCaseToLowerCase(networkType ?? '') && option.surcharge
        );
      }),
    [cardNetworkOptions, networkType],
  );

  const hidden =
    networkWithSurcharge === undefined ||
    cardNumberLength < MIN_CARD_LENGTH_CHECK_TYPE;

  useLayoutEffect(() => {
    if (!hidden && currencyCode) {
      const formattedAmount = formatAmount(
        locale,
        networkWithSurcharge?.surcharge ?? 0,
        currencyCode,
      );
      setLocalizedSurchargeAmount(`+${formattedAmount}`);
    }
  }, [hidden, currencyCode]);

  useLayoutEffect(() => {
    setSurchargeTextWidth(surchargeTextRef.current.clientWidth);
  }, [localizedSurchargeAmount]);

  return (
    <Root
      hidden={hidden}
      style={{ marginLeft: hidden ? -20 - surchargeTextWidth : 0 }}
    >
      <SurchargeText
        ref={surchargeTextRef}
        id='primer-checkout-card-surcharge-amount'
      >
        {localizedSurchargeAmount}
      </SurchargeText>
    </Root>
  );
};

export default SurchargeCard;
