import { BaseStore, IBaseState } from '../../store/BaseStore';

interface PaymentMethodPopupOverlayState extends IBaseState {
  isVisible: boolean;

  paymentMethodName?: string;
  logoSrc?: string;
  logoAltLabel?: string;
  background?: string;

  onCloseClick?: () => void;
  onFocusClick?: () => void;
}

const defaultState: PaymentMethodPopupOverlayState = {
  isVisible: false,
};

export class PaymentMethodPopupOverlayStore extends BaseStore<PaymentMethodPopupOverlayState> {
  showOverlay({
    paymentMethodName,
    logoSrc,
    logoAltLabel,
    background,
    onCloseClick,
    onFocusClick,
  }: {
    paymentMethodName?: string;
    logoSrc?: string;
    logoAltLabel?: string;
    background?: string;

    onCloseClick: () => void;
    onFocusClick: () => void;
  }) {
    this.produceState((draft) => {
      draft.isVisible = true;
      draft.paymentMethodName = paymentMethodName;
      draft.logoSrc = logoSrc;
      draft.logoAltLabel = logoAltLabel;
      draft.background = background;
      draft.onCloseClick = onCloseClick;
      draft.onFocusClick = onFocusClick;
    });
  }

  hideOverlay() {
    this.produceState((draft) => {
      draft.isVisible = false;
    });
  }
}

const createPaymentMethodPopupOverlayStore = () =>
  new PaymentMethodPopupOverlayStore(defaultState);
export default createPaymentMethodPopupOverlayStore;
