import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import {
  useCheckoutContext,
  useSelector,
} from '@primer-io/shared-library/contexts';
import {
  animationCurve,
  createWithStyle,
  getTextStyle,
} from '@primer-io/shared-library/components';
import styled from 'styled-components';
import { ElementID } from '../enums/Checkout';
import { useFirstRender, useIsFirstRender } from '../utils/hooks';

const DURATION = 500;

const withSmallPrintStyle = createWithStyle((style) => style?.smallPrint);

const Root = styled.p`
  transition: all 500ms ${animationCurve};
  margin-bottom: 0;

  &.PrimerCheckout--enter {
    opacity: 0;
    margin-top: 0em;
  }

  &.PrimerCheckout--entering,
  &.PrimerCheckout--entered {
    opacity: 1;
    margin-top: 1em;
  }

  &.PrimerCheckout--exit {
    opacity: 0;
    margin-top: 0em;
  }

  ${withSmallPrintStyle((style) => getTextStyle(style))}
`;

const SmallPrint = () => {
  const { viewUtils } = useCheckoutContext();
  const message = useSelector((s) => s.smallPrint.message);
  const [content, setContent] = useState<string | null>(null);
  const isVisible = useRef(false);
  const isFirstRender = useIsFirstRender();

  const toggleVisibility = (show: boolean, animated = true): void => {
    isVisible.current = false;

    viewUtils.toggleVisibilityAnimated(ElementID.DD_MANDATE_SMALL_PRINT, show, {
      duration: animated ? DURATION : 0,
      animateHeight: true,
      autoHeight: true,
      onFinish: () => {
        if (!show) {
          setContent(null);
        }
      },
    });
  };

  useFirstRender(() => {
    toggleVisibility(false, false);
  });

  useLayoutEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (!message) {
      toggleVisibility(false, true);
    } else {
      setContent(message);
    }
  }, [message]);

  useLayoutEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (content) {
      toggleVisibility(true, true);
    }
  }, [content]);

  return (
    <Root
      id='primer-checkout-small-print'
      className='PrimerCheckout--centered PrimerCheckout__label PrimerCheckout__label--muted PrimerCheckout__label--size-sm'
    >
      {content}
    </Root>
  );
};

export default SmallPrint;
