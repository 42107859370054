import { APIErrorShape } from '../core/Api';

export function hasAPIValidationError(
  error: APIErrorShape,
  fieldName: string,
): boolean {
  const key = `$.${fieldName}`;

  const err = error.validationErrors.find((validationError) =>
    validationError.errors.find((elm) => elm.path === key),
  );

  return !!err;
}
