import window from './window';
import { prepend } from './Node.prepend';

/**
 * There's an issue in iOS which prevents inputs within an iframe from dismissing the
 * keyboard when they blur. This creates a hidden input in the parent page and quickly focus/blurs it
 */
export function iosInputBlurFix(iframe: HTMLIFrameElement): void {
  if (window.document.activeElement !== window.document.body) {
    return;
  }

  const container = iframe.parentElement;

  if (container == null) {
    return;
  }

  let hiddenInput = container.querySelector('input');

  if (!hiddenInput) {
    hiddenInput = window.document.createElement('input');
    hiddenInput.setAttribute('type', 'button');
    hiddenInput.setAttribute(
      'style',
      'height:0px;width:0px;opacity:0;padding:0;position:absolute;left:-200%;top:0px;',
    );
    prepend(container, hiddenInput);
  }

  hiddenInput.focus();
  hiddenInput.blur();
}
