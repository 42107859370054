import { PaymentInstrumentType, PaymentMethodType } from '../types';

export function mapInstrumentTypeToPaymentMethodType(
  paymentInstrumentType: PaymentInstrumentType,
) {
  let paymentMethodType;
  switch (paymentInstrumentType) {
    case PaymentInstrumentType.PAYPAL_VAULTED:
      paymentMethodType = PaymentMethodType.PAYPAL;
      break;
    default:
      break;
  }
  return paymentMethodType;
}
