import window from '../utils/window';
import { toSelector } from './constants';
import { ClassName } from '../enums/Checkout';
import { toggleClass } from '../utils/toggleClass';
import { resolveElement } from '../utils/resolveElement';
import { hasClass } from '../utils/hasClass';
import { ElementOrID } from '../types';

export function getAttribute(
  elementOrId: ElementOrID,
  name: string,
  checkAncestor = false,
): Nullable<string> {
  const element = resolveElement(elementOrId);

  if (!element) {
    return null;
  }

  if (element.hasAttribute(name)) {
    return element.getAttribute(name);
  }

  return (
    element[name] ||
    (checkAncestor && getAttribute(element.parentElement, name, true)) ||
    null
  );
}

export function setValue(elementOrId: ElementOrID, value: string): void {
  const element = resolveElement(elementOrId);

  if (!element) {
    return;
  }

  const input = <HTMLInputElement>element;

  input.value = value;
}

export function massToggle(
  selector: ClassName,
  className: ClassName,
  predicate?: Callable<[HTMLElement], boolean>,
): void {
  eachElement(selector, (elm) => {
    toggleClass(elm, className, predicate ? predicate(elm) : false);
  });
}

export function toggleVisibility(element: ElementOrID, show?: boolean): void {
  return toggleClass(
    element,
    ClassName.HIDDEN,
    show == null ? !hasClass(element, ClassName.HIDDEN) : !show,
  );
}

export function eachElement(
  selector: ClassName,
  iterator: (el: HTMLElement) => void,
): void {
  window.document.querySelectorAll(toSelector(selector)).forEach(iterator);
}
