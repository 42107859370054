import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import Spinner from '../Spinner';
import { Title3 } from '../Text';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Img = styled.img`
  height: 44px;
  object-fit: contain;
`;

const StyledTitle3 = styled(Title3)`
  text-align: center;
`;

type Props = {
  imgSrc?: string;
  imgAlt?: string;

  text?: string;
};

const Loading: FunctionalComponent<Props> = ({ imgSrc, imgAlt, text }) => {
  return (
    <Root>
      {imgSrc && (
        <Img src={imgSrc} alt={imgAlt ?? text} data-testId='Loading/Img' />
      )}
      <Spinner />
      {text && <StyledTitle3 data-testId='Loading/Text'>{text}</StyledTitle3>}
    </Root>
  );
};

export default Loading;
