export function firstElementChild(parent: Node): Nullable<Node> {
  for (let i = 0; i < parent.childNodes.length; i += 1) {
    const child = parent.childNodes.item(i);

    if (child.nodeType === Node.ELEMENT_NODE) {
      return child;
    }
  }

  return null;
}
